/* ============
 * Mutations for the demobox module
 * ============
 *
 * The mutations that are available on the
 * demobox module.
 */

import * as types from "./mutation-types";

/* eslint-disable no-param-reassign */
export default {
  [types.SET_TOPLINES_LIST](state, toplinesList) {
    state.toplinesList = toplinesList;
  },
  // [types.SET_SECONDARY_DEMO_LIST](state, demoList) {
  //   state.secondaryDemoList = demoList;
  // },
  [types.REMOVE_DEMO_FROM_LIST](state, { id }) {
    state.toplinesList = state.toplinesList.filter((demo) => demo.id != id);
  },
};
