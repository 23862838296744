import PATH from "../../const/PATH";

export default {
  methods: {
    generatePath(asset, isDemo = false) {
      const Path = isDemo ? PATH.WAVE_PATH_DEMO : PATH.WAVE_PATH;
      return `${`${
        PATH.SERVICE_SERVER + Path + encodeURI(asset.waveform_prefix)
      }_${asset.id}`}.png`;
    },
  },
};
