<template>
  <div>
    <div class="fields-artwork">
      <a>
        <span :class="classObject" class="avatar square bg-warning-lt">
          <img
            v-if="
              item.artwork && item.artwork.size_2 && item.artwork.size_2 !== ''
            "
            :src="item.artwork.size_2"
            alt="."
          />
          <span v-else>{{
            item.name || item.title | firstLetter | Capitalize
          }}</span>
          <play-button
            v-if="haveSinglTrack(item)"
            :item="item"
            :active="active"
            :url="item.asset.single_file._single_file.url"
            :poster="
              item.artwork && item.artwork.size_2 ? item.artwork.size_2 : ''
            "
            :wave-url="generatePath(item.asset, true)"
            :name="name"
          />
          <play-button
            v-else-if="!!getExternalUrl(item)"
            :active="active"
            :item="item"
            :url="getExternalUrl(item)"
            :poster="
              item.artwork && item.artwork.size_2 ? item.artwork.size_2 : ''
            "
            :name="item.name || item.title"
            :soundcloud="!!getExternalUrl(item)"
          />
        </span>
      </a>
      <wave-generator
        v-if="haveSinglTrack(item) && !isSpotifyIdentifier"
        :is-demo="true"
        :asset="item.asset"
      />
    </div>
  </div>
</template>

<script>
import WaveGeneratorMixin from "../../../mixins/WaveGeneratorMixin";
import PlayButton from "../../../../components/PlayButton.vue";
import WaveGenerator from "../../WaveGenerator/Index.vue";
import singleTrackUrl from "../../../mixins/demo/singleTrackUrl";

/**
 * Field for Demo Asset
 */
export default {
  name: "TrackWithPlay",
  components: {
    PlayButton,
    WaveGenerator,
  },
  mixins: [WaveGeneratorMixin, singleTrackUrl],
  props: {
    item: {
      type: Object,
      required: true,
    },
    widthClass: {
      type: String,
      default: "w-48",
    },
    isCircle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    play() {
      return this.$store.state.player.play;
    },
    active() {
      const { trackUrl, currentUrl } = this;
      return currentUrl === trackUrl;
    },
    // haveTrack() {
    //   const { item } = this;
    //   console.log(this);
    // },

    name() {
      const name = this.item.name || this.item.title;
      return this.item.asset.status === "Demo"
        ? `${name}  <span class="ml1 badge badge-warning text-uppercase">${this.item.asset.status}</span>`
        : name;
    },
    classObject() {
      const { active } = this;
      return {
        circle: this.isCircle,
        "rounded-0": !this.isCircle,
        [this.widthClass]: true,
        loading: active,
      };
    },
    isSpotifyIdentifier() {
      return (
        this.haveSinglTrack(this.item) &&
        // eslint-disable-next-line no-underscore-dangle
        this.item.asset.single_file._single_file.url.includes("spotify:")
      );
    },
  },
};
</script>

<style scoped>
tr:hover .media-action {
  opacity: 1 !important;
  pointer-events: initial;
}
div.fields-artwork:hover .media-action {
  opacity: 1 !important;
  pointer-events: initial;
}
.fields-artwork {
  min-width: 60px;
}
</style>
