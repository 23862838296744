import BbProxy from "@/proxies/BbProxy";

class DemoSecondaryProxy extends BbProxy {
  constructor() {
    super("app/creative/demo/secondary");
  }

  getAllTracks() {
    return this.all()
      .then((data) => {
        if (!data) {
          return {
            demo: [],
            labels: [],
            total: 0,
          };
        }
        if (data.demo !== undefined) {
          const demos = data.demo.map((track) => {
            /**
             * checking if "file" exists
             */

            if (
              track.asset &&
              track.asset.file &&
              track.asset.file.length > 0
            ) {
              if (track.asset.file[0]._file0) {
                track.asset.single_file._single_file.url =
                  track.asset.file[0]._file0.url;
              }
            }

            return track;
          });

          localStorage.setItem("demoItemsSecondary", JSON.stringify(demos));
          data.demo = demos;
        } else {
          data.demo = [];
          console.error("releases not correct");
        }

        if (data.labels !== undefined) {
          localStorage.setItem(
            "demoItemsSecondaryLabels",
            JSON.stringify(data.labels)
          );
        } else {
          data.labels = [];
          console.error("labels not correct");
        }
        return data;
      })
      .catch(() => {
        console.log("Cannot load account info by route");
      });
  }
}

export default DemoSecondaryProxy;
