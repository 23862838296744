import {
  COMMENTS_UPDATE_COUNT,
  MODAL_OPEN_LICENSING,
  RELEASE_ASSETS_UPLOADED,
  ADD_NEW_RELEASE,
  CREATIVE_PROJECT_ASSET_MARKED_MAIN,
} from "./mutation-types";

/* eslint-disable no-param-reassign */
export default {
  [COMMENTS_UPDATE_COUNT](state, identifier) {
    state.comments = { identifier };
  },
  [CREATIVE_PROJECT_ASSET_MARKED_MAIN](state, creativeProject) {
    state.creativeProject = creativeProject;
  },
  [RELEASE_ASSETS_UPLOADED](state, identifier) {
    state.releaseAssets = { identifier };
  },
  [MODAL_OPEN_LICENSING](state, payload) {
    state.licensingModalOpen = {
      open: payload.open,
      trackData: payload.trackData ? payload.trackData : {},
    };
  },
  [ADD_NEW_RELEASE](state, payload) {
    state.metadataNewRows = {
      add: payload.add,
    };
  },
};
