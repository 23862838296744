import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = [];
  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.nested.release_schedule,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.nested.metadata,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.nested.release_page,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.nested.audio_assets,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.main,
  });

  return rules;
};
