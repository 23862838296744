import BbProxy from "@/proxies/BbProxy";

class PlaylistProxy extends BbProxy {
  constructor() {
    super("app/playlist");
  }

  setStatusToPlArtist(demoIdentifier, data) {
    const requestData = { demo_identifier: demoIdentifier };
    if (data.id) {
      requestData.deal_id = data.id;
    }
    return this.submit(
      "put",
      "/app/creative/licensing/artists/responded",
      requestData
    );
  }

  setStatusBacklogToPlArtist(demoIdentifier) {
    const requestData = { demo_identifier: demoIdentifier };
    return this.submit(
      "put",
      "/app/creative/licensing/artist/backlog",
      requestData
    );
  }

  setFollowStatus(trackId, status) {
    if (!["yes", "no"].includes(status)) {
      console.warn("Wrong playlist status");
      return Promise.reject();
    }
    const requestData = { pl_track_id: trackId, watching_status: status };
    return this.submit(
      "put",
      "/app/creative/licensing/status/watching",
      requestData
    );
  }
}

export default PlaylistProxy;
