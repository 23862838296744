<template>
  <div>
    <div :class="rootClass">
      <select
        :class="[selectColor]"
        :title="value"
        :style="{ width: _width }"
        class="custom-select"
        @change="onChange"
      >
        <option v-if="defaultValue !== ''" disabled selected="selected">
          {{ defaultValue }}
        </option>
        <template v-if="label">
          <option
            v-for="option in options"
            :key="`${option[label]}-${option.id}`"
            :selected="value == option[label]"
            :value="option.id"
          >
            {{ option[label] }}
          </option>
        </template>
        <template v-else>
          <option v-for="option in options" :selected="value == option">
            {{ option }}
          </option>
        </template>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    _width: {
      type: String,
      default: "150px",
    },
    rootClass: {
      type: Array,
      default: () => ["text-center"],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectColor() {
      if (this.value && typeof this.value === "string") {
        return `custom-select_status-${this.value
          .toLowerCase()
          .replace(" ", "-")}`;
      }
      return "";
    },
  },
  methods: {
    onChange(e) {
      let { value } = e.target;
      if (this.label) {
        value = this.options.find((option) => value == option.id);
      }
      this.$emit("changed", value);
    },
    reset() {},
  },
};
</script>
<style>
.custom-select {
  width: 150px;
}
.bg-dark .changed-selects .custom-select {
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  height: calc(1em + 0.75rem + 2px);
}
.status .custom-select {
  width: 100%;
  background-color: #464646;
}
.filter-select select {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}
</style>
