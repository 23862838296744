import BbProxy from "@/proxies/BbProxy";

class AccountProxy extends BbProxy {
  /**
   * The constructor for the ArtistProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("app/account", parameters);
  }

  find() {
    const t = +new Date();
    // Anti cash
    this.setParameter("t", t);
    return this.submit("get", `/${this.endpoint}/info`);
  }

  /**
   * Method used to get USER identifier and email  by token.
   *
   * @param {string} token .
   *
   * @returns {Promise} The result in a promise.
   */
  getDataByToken(token) {
    return this.submit("get", `/${this.endpoint}/registration/data/${token}`);
  }
}

export default AccountProxy;
