/* ============
 * State of the demobox module
 * ============
 *
 * The initial state of the demobox module.
 */

export default {
  toplinesList: [],
};
