<template>
  <div id="commentsInPopup" class="position-relative">
    <div class="card p-2 flex rs-mod-content__height">
      <div
        class="d-flex flex-wrap flex-column h-100 pt-1 rs-mod-content__height"
      >
        <Loader :is-loading="isLoading" :is-fixed="false" />
        <comments-listing
          :comments="modalDemo.comments"
          @deletingComment="deleteComment"
        />
        <div
          ref="sendCommentBlock"
          class="form-group mt-auto"
          style="margin-bottom: 0"
        >
          <div class="p-2">
            <form action="/" @submit.prevent="saveComment">
              <div class="form-row">
                <VueTribute
                  :options="optionsMention"
                  class="form-group col-12 form-comment"
                >
                  <div
                    id="mention-textarea-js"
                    contenteditable="true"
                    class="form-control mention-textarea"
                    data-ph='Type "@" to mention another user'
                    @input="mentionInputed"
                  />
                </VueTribute>
                <div id="menu-container">
                  <div ref="menuContainer" />
                </div>
              </div>
              <button type="submit" class="btn btn-secondary btn-save">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
      <!-- end .tab-content -->
    </div>
    <!-- end .card -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueTribute from "vue-tribute";
import { COMMENTS_UPDATE_COUNT } from "../../../../../store/modules/eventBus/mutation-types";
import CommentsListing from "../../../../../components/CommentsListing.vue";
import DemoCommentsProxy from "../../../../../proxies/creative/DemoCommentsProxy";
import Loader from "../../../../components/Table/Loader.vue";

export default {
  name: "LicensingDemoComments",
  components: {
    CommentsListing,
    VueTribute,
    Loader,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalDemo: this.track,
      isLoading: false,
      isDateLoading: false,
      firstLoad: true,
      commentHtml: "",
      commentText: "",
    };
  },
  computed: {
    ...mapGetters({
      releaseManagers: "account/releaseManagers",
    }),
    optionsMention() {
      return {
        trigger: "@",
        fillAttr: "name",
        // TODO: VueTribute can't use a number type; 'lookup: id' - doesn't work;
        lookup: "name",
        values: this.releaseManagers ? this.releaseManagers : [],
        menuContainer: this.$refs.menuContainer,
        selectTemplate: (item) =>
          `<span contenteditable="false" class="chosen-mention" data-id="${item.original.id}">${item.original.name}</span>`,
      };
    },
  },

  created() {
    this.loadMessages();
  },
  methods: {
    deleteComment(id) {
      this.isLoading = true;
      new DemoCommentsProxy()
        .destroy(id)
        .then(() => {
          this.loadMessages();
          this.$store.commit(
            `eventBus/${COMMENTS_UPDATE_COUNT}`,
            this.modalDemo.identifier
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.$notify({
            group: "error",
            type: "error",
            title: "ERROR",
            duration: 4000,
            text: "Can't delete message",
          });
        });
    },
    mentionInputed(e) {
      this.commentHtml = `<div>${e.target.innerHTML}</div>`;
      this.commentText = e.target.innerText;
    },
    // eslint-disable-next-line consistent-return
    loadMessages() {
      this.isLoading = true;
      const client = new DemoCommentsProxy();
      return client
        .all(this.track.identifier)
        .then((comments) => {
          this.modalDemo.comments = comments;
        })
        .catch(() => {
          console.error("Cannot load data");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveComment() {
      const comment = this.commentHtml;
      if (!this.commentText) {
        return false;
      }
      // eslint-disable-next-line no-undef
      const nodeComment = $(comment);
      const allMentionCollection = nodeComment.find(".chosen-mention");
      const divCollection = nodeComment.find("div");
      const userArray = [];
      if (allMentionCollection) {
        allMentionCollection.each((index, element) => {
          // eslint-disable-next-line no-undef
          const userId = $(element).data("id");
          const userName = $(element).text();
          $(element).text(`[user]${userName.trim()}[/user]`);

          if (!userArray.includes(userId)) {
            userArray.push(userId);
          }
        });
      }
      if (divCollection) {
        divCollection.each((index, element) => {
          // eslint-disable-next-line no-undef
          const content = $(element).html();
          $(element).html(`[br] ${content}`);
        });
      }

      const text = nodeComment.text().trim();

      // eslint-disable-next-line no-undef
      const editArea = $("#mention-textarea-js");

      this.isLoading = true;
      const inlineStyle =
        ' style="display: inline-block;color: #f4f5f7;background: #7e8d9a;border-radius: 20px;padding: 2px 5px;" ';
      const htmlMessageText = this.commentHtml.replace(
        /class="chosen-mention"/g,
        inlineStyle
      );
      const membersCSV = userArray.join(",");
      new DemoCommentsProxy()
        .create(this.modalDemo.identifier, text, htmlMessageText, membersCSV)
        .then(() => {
          const { modalRelease } = this;
          this.commentText = "";
          this.commentHtml = "";
          editArea.text("");
          this.loadMessages();
          this.$store.commit(
            `eventBus/${COMMENTS_UPDATE_COUNT}`,
            modalRelease.identifier
          );
        })
        .catch(() => {
          console.error("Can't update data");
        });
      return false;
    },
    releaseUpdated(release, curWeek, newWeek = null) {
      this.modalDemo = { ...release };
      this.$emit("updated-release", release, curWeek, newWeek);
    },
  },
};
</script>

<style scoped>
.mention-textarea {
  min-width: 100px;
  word-break: break-word;
  cursor: text;
  height: auto;
  min-height: 108px;
  white-space: pre-wrap;
}
.rs-mod-content__height {
  /*height: calc(100% - 46px);*/
  height: 100%;
  min-height: 320px;
}

@media screen and (max-width: 768px) {
  .rs-mod-content__height {
    height: auto;
    min-height: auto;
  }
}
.form-comment {
  /*margin-bottom: 0 !important;*/
  background-color: #252525;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 0px;
  padding-left: 0px;
}
.form-row {
  margin-right: 0px;
  margin-left: 0px;
}
.btn-save {
  color: #ffffff;
  background-color: #404040;
  border-color: #545454;
}
.btn-save:hover {
  background-color: #464646;
}
.card.rs-mod-content__height {
  /*min-height: 335px;*/
}
.comments-listing div {
  min-height: 130px;
}
</style>
