import BbProxy from "@/proxies/BbProxy";
import { API_PARAM_LABEL_ID } from "../../../const/labels";

/**
 * @param userId - id from Account User
 * @param userIdentifier - identifier from Account User
 */
class DemoRejectedData extends BbProxy {
  constructor(labelId, isSecondary = false) {
    const endpoint = !isSecondary
      ? "app/creative/demos/rejected/default"
      : "app/creative/demos/rejected/secondary";
    super(endpoint);
    this.setParameter(API_PARAM_LABEL_ID, labelId);
  }
}

export default DemoRejectedData;
