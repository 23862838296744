import BbProxy from "@/proxies/BbProxy";
import { appVars } from "@/const/AppVars";

class DemoPrimaryProxy extends BbProxy {
  constructor(parameters = {}, config = {}) {
    if (appVars.IsMockDataEnabled) {
      const { host } = window.location;
      super(`//${host}/demobox.json`);
    } else {
      super("app/creative/demo/default", parameters, config);
    }
  }

  getAllTracks() {
    return this.all()
      .then((data) => {
        if (!data) {
          return {
            demo: [],
            labels: [],
            total: 0,
          };
        }
        if (data.demo !== undefined) {
          const demos = data.demo.map((track) => {
            /**
             * checking if "file" exists
             */

            if (
              track.asset &&
              track.asset.file &&
              track.asset.file.length > 0
            ) {
              if (track.asset.file[0]._file0) {
                track.asset.single_file._single_file.url =
                  track.asset.file[0]._file0.url;
              }
            }

            return track;
          });

          data.demo = demos;
        } else {
          data.demo = [];
          console.error("releases not correct");
        }

        // eslint-disable-next-line no-empty
        if (data.labels !== undefined) {
        } else {
          data.labels = [];
          console.error("labels not correct");
        }

        return data;
      })
      .catch(() => {
        console.log("Cannot load account info by route");
      });
  }
}

export default DemoPrimaryProxy;
