import briefing from "@/store/ability/AbilitiesByModule/creative/briefing";
import catalog from "./catalog";
import demoBox from "./demobox";
import { RESOURCE } from "../../../../routes/const";

export default () => {
  let rules = [];
  /* Module Creative */
  rules = rules.concat(catalog()).concat(demoBox()).concat(briefing());

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.main,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.dashboard,
  });

  return rules;
};
