<template>
  <div class="main-loader spinner-grow text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "LoadingGrow",
};
</script>

<style scoped>
.main-loader {
  z-index: 1020;
}
</style>
