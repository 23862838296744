/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import * as types from "./mutation-types";
import ROLES from "../../../const/Roles";
import defineHomePage from "../../ability/defineHomePage";
import getDefaultAccountState from "./default";

/* eslint-disable no-param-reassign */
export default {
  updateBankCountry(state, value) {
    this.state.account.rightholder.bank_country = value;
  },
  [types.FIND](state, account) {
    let { currentLabel } = state;

    const isRightholder =
      account.roles &&
      Array.isArray(account.roles) &&
      (account.roles.includes(ROLES.RIGHTHOLDER) ||
        account.roles.includes(ROLES.RIGHTHOLDER_MANAGER));

    const newState = {
      email: account.email,
      firstName: account.firstName,
      lastName: account.lastName,
      rightholder: account.rightholder,
      photo: account.photo,
      labels: account.labels,
      id: account.id,
      identifier: account.identifier,
      roles: account.roles && Array.isArray(account.roles) ? account.roles : [],
      rightholders:
        account.rightholders && Array.isArray(account.rightholders)
          ? account.rightholders
          : [],
      webRoles:
        account.webRoles && Array.isArray(account.webRoles)
          ? account.webRoles
          : [],
      isRightholder,
      permissions: account.permissions,
    };

    const newStateLabelsIds = newState.labels.map((label) => label.id);
    if (
      ((!state.currentLabel || !Number.isInteger(state.currentLabel)) &&
        Array.isArray(newState.labels) &&
        newState.labels.length > 0) ||
      (Array.isArray(newState.labels) &&
        newState.labels.length > 0 &&
        !newStateLabelsIds.includes(state.currentLabel))
    ) {
      const defaultLabel = newState.labels[0];
      currentLabel = defaultLabel.id ? defaultLabel.id : null;
    }
    newState.currentLabel = currentLabel;
    newState.homepage = defineHomePage(newState, currentLabel);

    Object.assign(state, newState);
  },
  [types.SET_CURRENT_LABEL](state, id) {
    state.currentLabel = id;
    state.homepage = defineHomePage(state, state.currentLabel);
  },
  [types.LOADING_ACCOUNT_DATA](state, loading) {
    state.loading = loading;
  },
  [types.LOADING_RELEASE_MANAGERS](state, releaseManagers) {
    state.releaseManagers = releaseManagers;
  },
  [types.LOADING_ASSIGNED_USERS_IN_DEAL](state, assignedUsersInDeal) {
    state.assignedUsersInDeal = assignedUsersInDeal;
  },
  [types.RESET](state) {
    Object.assign(state, getDefaultAccountState());
  },
};
