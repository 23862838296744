/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const TRACK = "TRACK";
export const RUN = "RUN";
export const OPEN_ASIDE = "OPEN_ASIDE";
export const CLEAR_PLAYER = "CLEAR_PLAYER";

export default {
  TRACK,
  RUN,
  OPEN_ASIDE,
  CLEAR_PLAYER,
};
