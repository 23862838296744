import Proxy from "../Proxy";
import NoCredentialMixin from "../../mixins/proxyNoCredensials";
import PATH from "../../const/PATH";

/**
 * @param userId - id from Account User
 * @param userIdentifier - identifier from Account User
 */
class DashboardFinanceBalanceProxy extends Proxy {
  constructor(userId, userIdentifier) {
    // super('app/financical/country');
    super(
      `${PATH.SERVICE_SERVER}api/${PATH.WAVE_SLUG}financial/balance/statistics`,
      {},
      {
        headers: {
          Authorization: `${userId}:${userIdentifier}`,
        },
      }
    );
  }
}

Object.assign(DashboardFinanceBalanceProxy.prototype, NoCredentialMixin);
export default DashboardFinanceBalanceProxy;
