import store from "@/store";
import BbProxy from "@/proxies/BbProxy";

class LicensingTrackDataProxy extends BbProxy {
  constructor() {
    const { currentLabel } = store.state.account;
    super("app/creative/licensing/track");
    this.setParameter("label_id", currentLabel);
  }

  getTracksData(demoIdentifier) {
    return this.submit("get", `${this.endpoint}/${demoIdentifier}`);
  }
}

export default LicensingTrackDataProxy;
