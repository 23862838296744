/**
 *
 * @param account
 * @param {array} account.label_managers
 * @param {array} account.finance
 * @param {array} account.roles
 * @param {number} currentLabel
 * @returns {[]}
 */
import rightholderStatementManager from "@/store/ability/AbilitiesByModule/finance/rightholderStatementManager";
import manager from "./manager";
import rightHolder from "./rightholder";
import { RESOURCE } from "../../../../routes/const";
import ROLES from "../../../../const/Roles";

export default (account, currentLabel) => {
  const {
    permissions: { label_managers: labelManagers, finance },
    isRightholder,
    roles,
  } = account;
  let rules = [];

  if (labelManagers.includes(currentLabel) || finance.includes(currentLabel)) {
    rules = rules.concat(manager());
  }
  if (isRightholder) {
    rules = rules.concat(rightHolder());
  }

  if (roles.includes(ROLES.RIGHTHOLDER_STATEMENT_MANAGER)) {
    rules = rules.concat(rightholderStatementManager());
  }

  /*
   * Access from page rightholder
   *  #/finance/rightholder as rightholder for manager
   */
  if (roles.includes(ROLES.RIGHTHOLDER_ADMIN)) {
    rules.push({
      action: "manage",
      subject: RESOURCE.finance.admin,
    });
  }

  return rules;
};
