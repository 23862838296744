/* ============
 * Actions for the demobox module
 * ============
 *
 * The actions that are available on the
 * demobox module.
 */

import moment from "moment";
import DemoPrimaryProxy from "../../../../proxies/creative/demobox/DemoPrimaryProxy";
import DemoSecondaryProxy from "../../../../proxies/creative/demobox/DemoSecondaryProxy";
import DemoPlusMinusProxy from "../../../../proxies/creative/demobox/DemoPlusMinusProxy";
import { API_PARAM_LABEL_ID } from "../../../../const/labels";
import * as types from "./mutation-types";
import DemoRejectedData from "../../../../proxies/creative/demobox/DemoRejectedData";
import DemoChangeLabelProxy from "../../../../proxies/creative/demobox/DemoChangeLabel";
import DemoStatusProxy from "../../../../proxies/creative/demobox/approvalProxy/DemoStatusProxy";
import DemoApprovedProxy from "../../../../proxies/creative/demobox/DemoApprovedProxy";
import DemoSignedProxy from "../../../../proxies/creative/demobox/DemoSignedProxy";
import DemoSetRejected from "../../../../proxies/creative/DemoSetRejected";
import DemoSetBlackListArtist from "../../../../proxies/creative/DemoSetBlackListArtist";

export const fetchAllPrimaryTracks = ({ commit, rootState }) =>
  new Promise((resolve, reject) => {
    const client = new DemoPrimaryProxy();

    if (rootState.account.currentLabel) {
      client.setParameter(API_PARAM_LABEL_ID, rootState.account.currentLabel);
    }

    client
      .getAllTracks()
      .then((response) => {
        if (
          client.parameters[API_PARAM_LABEL_ID] ===
          rootState.account.currentLabel
        ) {
          commit(types.SET_PRIMARY_DEMO_LIST, response.demo);
        } else {
          reject();
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.log("Cannot fetchAllPrimaryTracks info!");
        // TODO: maybe add call to analytics or debug app?
      });
  });

export const fetchAllSecondaryTracks = ({ commit, rootState }) =>
  new Promise((resolve, reject) => {
    const client = new DemoSecondaryProxy();
    if (rootState.account.currentLabel) {
      client.setParameter(API_PARAM_LABEL_ID, rootState.account.currentLabel);
    }

    client
      .getAllTracks()
      .then((response) => {
        if (
          client.parameters[API_PARAM_LABEL_ID] ===
          rootState.account.currentLabel
        ) {
          commit(types.SET_SECONDARY_DEMO_LIST, response.demo);
        } else {
          // TODO: add normal cancelation
          reject();
        }

        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.log("Cannot fetchAllSecondaryTracks info!");
        // TODO: maybe add call to analytics or debug app?
      });
  });

export const clickPlusOnDemo = (
  { commit },
  { type, id, path, notification }
) => {
  // TODO: create api request
  const labelSlug = path;
  const client = new DemoPlusMinusProxy(labelSlug, type);
  const data = {
    demo_id: id,
    action: "sign",
    label: labelSlug,
    skip_notification: !notification,
  };
  return new Promise((resolve, reject) => {
    client
      .update(0, data)
      .then((response) => {
        if (response.success) {
          commit(types.REMOVE_DEMO_FROM_LIST, {
            type,
            id,
          });
          resolve(response);
          return response;
        }
        reject(new Error("response success is not set"));
        return response;
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const clickMinusOnDemo = (
  { commit },
  { type, id, notification, path }
) =>
  new Promise((resolve, reject) => {
    const client = new DemoPlusMinusProxy(path, type);
    const action = "reject";
    const data = {
      demo_id: id,
      action,
      label: path,
      skip_notification: !notification,
    };
    client
      .update(0, data)
      .then(() => {
        new DemoStatusProxy(id, action)
          .all()
          .then((response) => {
            if (response.data && response.data.status === "Rejected") {
              commit(types.SET_STATUS, {
                type,
                id,
                status: response.data.status,
              });
              resolve(response.data.status);
              return;
            }
            reject(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const fetchAllApprovedTracks = ({ commit, rootState }) => {
  commit(types.SET_APPROVED_LOADING, true);

  return new Promise((resolve, reject) => {
    const approvedProxy = new DemoApprovedProxy();
    approvedProxy
      .setParameters({
        [API_PARAM_LABEL_ID]: rootState.account.currentLabel,
      })
      .allTracks()
      .then((response) => {
        if (
          approvedProxy.parameters[API_PARAM_LABEL_ID] ===
          rootState.account.currentLabel
        ) {
          if (response && Array.isArray(response)) {
            commit(types.SET_PRIMARY_APPROVED_DATA, response);
          }
        } else {
          reject();
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        commit(types.SET_APPROVED_LOADING, false);
      });
  });
};

export const fetchAllSignedTracks = ({ commit, rootState }) =>
  new Promise((resolve, reject) => {
    const demoSignedProxy = new DemoSignedProxy();
    commit(types.SET_SIGNED_LOADING, true);
    demoSignedProxy
      .setParameters({
        offset: "0",
        limit: 300,
        date_from: moment().add(-8, "weeks").startOf("day").format(),
        [API_PARAM_LABEL_ID]: rootState.account.currentLabel,
      })
      .allTracks()
      .then((response) => {
        if (
          demoSignedProxy.parameters[API_PARAM_LABEL_ID] ===
          rootState.account.currentLabel
        ) {
          if (response && Array.isArray(response)) {
            commit(types.SET_SIGNED_DATA, response);
          }
        } else {
          reject();
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        commit(types.SET_SIGNED_LOADING, false);
      });
  });

export const fetchAllPrimaryRejectedTracks = ({ commit, rootState }) =>
  new Promise((resolve, reject) => {
    const clientRejected = new DemoRejectedData(rootState.account.currentLabel);
    clientRejected
      .setParameters({
        offset: "0",
        limit: 100,
        date_from: moment().add(-8, "weeks").startOf("day").format(),
        [API_PARAM_LABEL_ID]: rootState.account.currentLabel,
      })
      .allTracks()
      .then((response) => {
        if (
          clientRejected.parameters[API_PARAM_LABEL_ID] ===
          rootState.account.currentLabel
        ) {
          if (response && Array.isArray(response)) {
            commit(types.SET_PRIMARY_REJECT_DATA, response);
          }
        } else {
          reject();
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const fetchAllSecondaryRejectedTracks = ({ commit, rootState }) =>
  new Promise((resolve, reject) => {
    const clientRejected = new DemoRejectedData(
      rootState.account.currentLabel,
      true
    );
    clientRejected
      .setParameters({
        offset: "0",
        limit: 400,
        date_from: moment().add(-8, "weeks").startOf("day").format(),
      })
      .allTracks()
      .then((response) => {
        if (
          clientRejected.parameters[API_PARAM_LABEL_ID] ===
          rootState.account.currentLabel
        ) {
          if (response && Array.isArray(response)) {
            commit(types.SET_SECONDARY_REJECT_DATA, response);
          }
        } else {
          reject();
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const clearStorePrimaryData = ({ commit }) => {
  commit(types.CLEAR_PRIMARY_DATA);
};

export const clearStoreSecondaryData = ({ commit }) => {
  commit(types.CLEAR_SECONDARY_DATA);
};

export const changeDemoLabel = (
  { commit },
  { type, id, identifier, newLabelId }
) =>
  new Promise((resolve, reject) => {
    const client = new DemoChangeLabelProxy();
    client
      .setLabel(identifier, newLabelId)
      .then((response) => {
        commit(types.REMOVE_DEMO_FROM_LIST, {
          type,
          id,
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const rejectDemo = (
  { commit },
  { identifier, skipNotification, type, id }
) =>
  new Promise((resolve, reject) => {
    const client = new DemoSetRejected(identifier);
    client
      .reject(skipNotification)
      .then((response) => {
        commit(types.REMOVE_DEMO_FROM_LIST, {
          type,
          id,
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const blackListArtist = (
  { commit },
  { identifier, skipNotification, type, id }
) =>
  new Promise((resolve, reject) => {
    const client = new DemoSetBlackListArtist(identifier);
    client
      .blacklist(skipNotification)
      .then((response) => {
        commit(types.REMOVE_DEMO_FROM_LIST, {
          type,
          id,
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  fetchAllPrimaryTracks,
  fetchAllSecondaryTracks,
  clickPlusOnDemo,
  clickMinusOnDemo,
  fetchAllPrimaryRejectedTracks,
  fetchAllSecondaryRejectedTracks,
  clearStorePrimaryData,
  clearStoreSecondaryData,
  changeDemoLabel,
  rejectDemo,
  fetchAllApprovedTracks,
  fetchAllSignedTracks,
  blackListArtist,
};
