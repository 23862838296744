import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = [];
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.primary,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.secondary,
  });
  return rules;
};
