import Proxy from "./Proxy";
import ProxyNoCredensials from "../mixins/proxyNoCredensials";
import PATH from "../const/PATH";

class WaveProxy extends Proxy {
  /**
   * The constructor for the ArtistProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}, demo = false) {
    let path = `${PATH.SERVICE_SERVER}api/${PATH.WAVE_SLUG}generatewaves`;
    if (demo) {
      path = `${PATH.SERVICE_SERVER}api/${PATH.WAVE_SLUG}generatewaves/demo`;
    }
    super(path, parameters);
  }
}

Object.assign(WaveProxy.prototype, ProxyNoCredensials);

export default WaveProxy;
