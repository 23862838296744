import store from "@/store";
import Proxy from "../../../Proxy";
import NoCredentialMixin from "../../../../mixins/proxyNoCredensials";
import PATH from "../../../../const/PATH";

class DemoStatusProxy extends Proxy {
  constructor(demoId) {
    const { id, identifier } = store.state.account;
    super(
      `${PATH.SERVICE_SERVER}api/creative/demobox/status/${demoId}`,
      { label_id: store.state.account.currentLabel },
      {
        headers: {
          Authorization: `${id}:${identifier}`,
        },
      }
    );
  }
}

Object.assign(DemoStatusProxy.prototype, NoCredentialMixin);

export default DemoStatusProxy;
