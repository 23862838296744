import { FIND, SET_CURRENT_LABEL } from "../modules/account/mutation-types";
import types from "../modules/rightholder/mutation-types";
import { API_PARAM_LABEL_ID } from "../../const/labels";
import DashboardFinanceBalanceProxy from "../../proxies/finance/DashboardFinanceBalanceProxy";

export default (store) =>
  // eslint-disable-next-line prefer-destructuring

  store.subscribe((mutation, state) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case `account/${SET_CURRENT_LABEL}`:
      case `account/${FIND}`: {
        if (state.account.isRightholder && state.account.currentLabel) {
          new DashboardFinanceBalanceProxy(
            state.account.id,
            state.account.identifier
          )
            .setParameter(API_PARAM_LABEL_ID, state.account.currentLabel)
            .all()
            .then((response) => {
              if (response.data) {
                store.commit(
                  `rightholder/${types.SET_RIGHTHOLDER_STATEMENTS}`,
                  response.data
                );
                store.dispatch("finance/setWithDrawInfo");
              }
            });
        }
        break;
      }
    }
  });
