/* ============
 * Getters for the demobox module
 * ============
 *
 * The getters that are available on the
 * demobox module.
 */
import { ALL as LABEL_ALL } from "../../../../const/labels";

export default {
  toplinesList(state) {
    return state.toplinesList;
  },

  primaryFilters(state) {
    const filters = {};

    filters[LABEL_ALL] = {
      count: state.toplinesList.length,
    };

    state.toplinesList.forEach((demo) => {
      if (typeof demo.status === "string") {
        if (typeof filters[demo.status] === "undefined") {
          filters[demo.status] = {
            count: 1,
          };
        } else {
          filters[demo.status].count =
            parseInt(filters[demo.status].count, 10) + 1;
        }
      }
    });
    return filters;
  },
};
