import {
  RESOURCE,
  BACKOFFICE_RELEASE_SINGLE_PAGE,
  BACKOFFICE_ISSUE_SINGLE_PAGE,
  BACKOFFICE_RELEASE_SCHEDULED,
} from "../const";
import beforeEnter from "../setLabelBeforeEnter";

export default {
  path: "/backoffice",
  name: "backoffice.index",
  component: () => import("@/views/Backoffice/Index.vue"),
  redirect: "/backoffice/release-schedule",
  // If the user needs to be authenticated to view this page.
  children: [
    {
      path: "/labels/all",
      component: () => import("@/views/labelsAdmin/pages/Labels/LabelsAll.vue"),
      name: "labels.all",
      meta: {
        auth: true,
        resource: RESOURCE.labels.all,
      },

      children: [
        {
          path: "/backoffice/labels/:id/edit",
          component: () =>
            import("@/views/labelsAdmin/pages/Labels/LabelEdit.vue"),
          name: "labels.edit",
          meta: {
            auth: true,
            resource: RESOURCE.labels.all,
          },
        },
        {
          path: "/backoffice/labels/create",
          component: () =>
            import("@/views/labelsAdmin/pages/Labels/LabelCreate.vue"),
          name: "labels.create",
          meta: {
            auth: true,
            resource: RESOURCE.labels.all,
          },
        },
      ],
    },
    {
      path: "metadata",
      component: () => import("@/views/Backoffice/MetaData/Index.vue"),
      name: "backoffice.metadata",
      redirect: "/backoffice/metadata/backlog",
      children: [
        {
          path: "backlog",
          component: () =>
            import("@/views/Backoffice/MetaData/Backlog/Index.vue"),
          name: "backoffice.metadata.backlog",
          meta: {
            auth: true,
            resource: RESOURCE.backoffice.nested.metadata,
          },
        },
        {
          path: "prerelease",
          component: () =>
            import("@/views/Backoffice/MetaData/Prerelease/Index.vue"),
          name: "backoffice.metadata.prerelease",
          meta: {
            auth: true,
            resource: RESOURCE.backoffice.nested.metadata,
          },
        },
      ],
    },
    {
      path: "release-schedule",
      component: () => import("@/views/Backoffice/Releaseschedule/Index.vue"),
      name: BACKOFFICE_RELEASE_SCHEDULED,
      meta: {
        auth: true,
        resource: RESOURCE.backoffice.nested.release_schedule,
      },
    },
    {
      path: "catalog",
      component: () => import("@/views/Backoffice/Catalog/Index.vue"),
      name: "backoffice.catalog",
      redirect: "/backoffice/catalog/releases",
      children: [
        {
          path: "",
          redirect: "releases",
        },
        {
          path: "releases",
          component: () =>
            import("@/views/Backoffice/Catalog/Releases/Index.vue"),
          name: "backoffice.catalog.releases",
          meta: {
            auth: true,
            resource: RESOURCE.backoffice.nested.catalog,
          },
        },
        {
          path: "artists",
          component: () =>
            import("@/views/Backoffice/Catalog/Artists/Index.vue"),
          name: "backoffice.catalog.artists",
          meta: {
            auth: true,
            resource: RESOURCE.backoffice.nested.artists,
          },
        },
      ],
    },

    {
      path: "audio-assets",
      component: () => import("@/views/Backoffice/Audioassets/Index.vue"),
      name: "backoffice.audioassets",
      meta: {
        auth: true,
        resource: RESOURCE.backoffice.nested.audio_assets,
      },
    },
    {
      path: "final-master-status",
      component: () => import("@/views/Backoffice/FinalMasterStatus/Index.vue"),
      name: "backoffice.final_master_status",
      meta: {
        auth: true,
        resource: RESOURCE.backoffice.main,
      },
    },
    {
      path: "legal-status",
      component: () => import("@/views/Backoffice/LegalStatus/Index.vue"),
      name: "backoffice.legal_status",
      meta: {
        auth: true,
        resource: RESOURCE.backoffice.main,
      },
    },
    {
      path: "artwork-status",
      component: () => import("@/views/Backoffice/ArtworkStatus/Index.vue"),
      name: "backoffice.artwork_status",
      meta: {
        auth: true,
        resource: RESOURCE.backoffice.main,
      },
    },
    {
      path: "project-board",
      component: () => import("@/views/Backoffice/ProjectBoard/Index.vue"),
      name: "backoffice.projectBoard",
      meta: {
        auth: true,
        resource: RESOURCE.backoffice.nested.project_pages,
      },
    },
    {
      path: ":parentLabelPath/issue/:id",
      component: () => import("@/views/Backoffice/IssueSinglePage/Index.vue"),
      name: BACKOFFICE_ISSUE_SINGLE_PAGE,
      meta: {
        auth: true,
        action: "auth",
        resource: RESOURCE.loggedIn,
      },
      beforeEnter,
    },
    {
      path: "release/:parentLabelPath/:identifier",
      component: () => import("@/views/Backoffice/Release/Index.vue"),
      name: BACKOFFICE_RELEASE_SINGLE_PAGE,
      beforeEnter,
      meta: {
        auth: true,
        action: "auth",
        resource: RESOURCE.loggedIn,
      },
    },
  ],
};
