import { RESOURCE } from "../../../../routes/const";
import licensingUser from "./licensingUser";

export default () => {
  let rules = [];
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.unassigned,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.licensingDashboard,
  });
  rules = rules.concat(licensingUser());

  return rules;
};
