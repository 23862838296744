<template>
  <h4>
    {{ title }}
  </h4>
</template>

<script>
export default {
  name: "HalfBlockTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1.5rem !important;
}
</style>
