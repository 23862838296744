import store from "@/store";
import { API_PARAM_LABEL_ID } from "../../const/labels";
import PATH from "@/const/PATH";
import BbProxy from "@/proxies/BbProxy";

class ToplinesProxy extends BbProxy {
  constructor() {
    const { currentLabel } = store.state.account;
    super("app/creative/toplines");
    this.setParameter(API_PARAM_LABEL_ID, currentLabel);
  }

  sign(identifier, item) {
    const request = { ...item };
    const { currentLabel } = store.state.account;
    request.label_id = currentLabel;
    return this.submit("put", `/${this.endpoint}/sign/${identifier}`, request);
  }

  search(q) {
    this.setParameter("q", q);
    return this.submit("get", "app/search/toplines");
  }

  searchPlArtists(q) {
    this.setParameter("q", q);
    return this.submit("get", "app/search/topline-ready/playlist-artists");
  }

  searchContacts(q) {
    this.setParameter("q", q);
    return this.submit("get", "app/search/playlist-artists/contacts");
  }

  searchContactsByPlArtist(artistId) {
    return this.submit("get", `/app/pl-artists/${artistId}/contacts`);
  }

  getPitches(identifier) {
    return this.submit("get", `app/creative/toplines/${identifier}/pitches`);
  }

  getAllTracks(type) {
    this.setParameter("type", type);
    return this.all()
      .then((response) => response.data)
      .then((response) => {
        if (!response) {
          return [];
        }
        if (Array.isArray(response) && response !== []) {
          return response.map((track) => {
            /**
             * checking if "file" exists
             */

            if (
              track.asset &&
              track.asset.file &&
              track.asset.file.length > 0
            ) {
              if (track.asset.file[0]._file0) {
                track.asset.single_file._single_file.url =
                  track.asset.file[0]._file0.url;
              }
            }

            return track;
          });
        }
        return [];
      })
      .catch(() => {
        console.log("Cannot load account info by route");
      });
  }

  getAssets(id) {
    return this.submit("get", `app/creative/toplines/${id}/audio-assets`);
  }

  getSuggestions(identifier) {
    return this.submit(
      "get",
      `app/creative/toplines/suggestions/${identifier}`
    );
  }

  getUsers() {
    return new Promise((resolve, reject) => {
      this.submit("get", "app/creative/toplines/users")
        .then((responses) => {
          const users =
            responses.data &&
            responses.data.users &&
            Array.isArray(responses.data.users)
              ? responses.data.users
              : [];
          if (PATH.ENV === "production") {
            return resolve({
              data: { users: users.filter((user) => user.id !== 6489) },
            });
          }
          return resolve({ data: { users } });
        })
        .catch((e) => {
          console.error(e);
          reject(new Error("app/creative/toplines/users wrong data"));
        });
    });
  }

  relatedComments(id) {
    return this.submit("get", `app/creative/project/${id}/comments`);
  }

  /**
   *
   * @param data
   * @param data.artists_id
   * @param data.new_artists
   * @param data.track_identifier
   * @link https://knightvision-staging.bettyblocks.com/#custom_endpoints:qs=cached_full_path:backoffice%2Fadd&f=all/edit/6d478c7489254bdc8483c038b985bb4b
   * @returns {Promise}
   */
  setGenres(data) {
    return this.submit("post", "/app/backoffice/add/main/artist", data);
  }

  deleteGenres(identifier) {
    return this.submit(
      "delete",
      `/app/backoffice/tracks/${identifier}/delete-artists`
    );
  }
}

export default ToplinesProxy;
