import { ability } from "@/plugins/abilities";
import { FIND, SET_CURRENT_LABEL } from "../modules/account/mutation-types";
import defineAbilities from "./defineAbilities";
import { LOGOUT } from "../modules/auth/mutation-types";

export default (store) => {
  // eslint-disable-next-line prefer-destructuring
  ability.update(
    defineAbilities(store.state.account, store.state.account.currentLabel)
  );

  return store.subscribe((mutation, state) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case `account/${SET_CURRENT_LABEL}`:
      case `account/${FIND}`: {
        const { account } = state;
        const rules = defineAbilities(account, account.currentLabel);
        ability.update(rules);
        break;
      }
      case `auth/${LOGOUT}`:
        ability.update([]);
        break;
    }
  });
};
