import licensingManager from "./licensingManger";
import creativeMain from "./creativeMain";

import licensingUser from "./licensingUser";

export default (account, curLabelData) => {
  let rules = [];
  const {
    permissions: {
      label_managers: labelManagers,
      labels_manager_licensing: managerLicensing,
      licensing,
    },
  } = account;

  if (curLabelData && curLabelData.label_type === "Record label") {
    if (managerLicensing.includes(curLabelData.id)) {
      rules = rules.concat(licensingManager()).concat(creativeMain());
    }
    if (licensing.includes(curLabelData.id)) {
      rules = rules.concat(licensingUser()).concat(creativeMain());
    }
    if (labelManagers.includes(curLabelData.id)) {
      rules = rules
        .concat(creativeMain())
        .concat(licensingManager())
        .concat(licensingUser());
    }
  }

  return rules;
};
