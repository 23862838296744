import Vue from "vue";
import BaseProxy from "@/proxies/Proxy";
import { AxiosError } from "axios";
import { appVars } from "@/const/AppVars";

class BbProxy extends BaseProxy {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  submit(requestType, url, data = null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { config } = this;

    const endpoint =
      appVars.BBApiPrefix + (url.startsWith("/") ? "" : "/") + url;
    if (requestType.toLowerCase() !== "get" && this.csrf) {
      return this.submitCsrf(requestType, endpoint, data);
    }

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Vue.$http[requestType](endpoint + this.getParameterString(), data, config)
        .then((response: { data: unknown }) => {
          resolve(response.data);
        })
        .catch(({ response }: AxiosError) => {
          if (response) {
            reject(response.data);
          } else {
            reject();
          }
        });
    });
  }
}

export default BbProxy;
