/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vuex from "vuex";
import { Ability } from "@casl/ability";
import VuexPersistence from "vuex-persist";
import abilityPlugin from "./ability/ability";

// Modules

import account from "./modules/account";
import auth from "./modules/auth";
import player from "./modules/player";
import setting from "./modules/setting";

import demobox from "./modules/creative/demobox";
import finance from "./modules/finance";

import eventBus from "./modules/eventBus";

import toplines from "./modules/creative/toplines";
import rightholder from "./modules/rightholder";
import rightholderStatements from "./modules/rightholderStatements";
import rightholderPlugin from "./plugins/rightholder";
import redirectSwitchLabel from "./plugins/redirectSwitchLabel";
import defineAbilities from "./ability/defineAbilities";
import releaseManagers from "./plugins/releaseManagers";
import AssignedUsersInDeal from "./plugins/AssignedUsersInDeal";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    setting: state.setting,
    account: state.account,
    finance: state.finance,
    toplines: state.toplines,
    rightholder: state.rightholder,
    rightholderStatements: state.rightholderStatements,
  }),
});

export default new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    account,
    auth,
    player,
    setting,
    demobox,
    finance,
    toplines,
    rightholder,
    rightholderStatements,
    eventBus,
  },

  /**
   * If strict mode should be enabled.
   */
  getters: {
    ability(state) {
      const curAccount = state.account;
      const rules = defineAbilities(curAccount, curAccount.currentLabel);
      return new Ability(rules, {});
    },
  },

  /**
   * Plugins used in the store.
   */
  plugins: [
    vuexLocal.plugin,
    abilityPlugin,
    rightholderPlugin,
    redirectSwitchLabel,
    releaseManagers,
    AssignedUsersInDeal,
  ],
});
