const Server = import.meta.env.VITE_WAVE_GENERATES_SERVER;
const ASSETS_PATH = import.meta.env.VITE_ASSETS_PATH;
const ICONS = import.meta.env.VITE_ICONS;
const SLUG = import.meta.env.VITE_WAVE_SLUG;
const ENV = import.meta.env.VITE_PERM_ENV;

const PATH = {
  ASSET_PATH: ASSETS_PATH,
  SVG_PATH: ICONS,
  WAVE_PATH: `storage/${SLUG}waveforms/`,
  WAVE_PATH_DEMO: `storage/${SLUG}waveforms/demo/`,
  SERVICE_SERVER: Server,
  WAVE_SLUG: SLUG,
  ENV,
};

export default PATH;
