import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = [];

  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.nested.catalog,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.backoffice.nested.artists,
  });
  return rules;
};
