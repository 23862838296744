export default {
  comments: {
    identifier: null,
  },
  creativeProject: {
    assetIdentifier: "",
    projectIdentifier: "",
    asset: {},
  },
  releaseAssets: {
    identifier: null,
  },
  licensingModalOpen: {
    open: false,
    trackData: {},
  },
  metadataNewRows: {
    add: false,
  },
};
