// eslint-disable-next-line no-undef
$(document).on("shown.bs.dropdown", ".table-responsive", (e) => {
  // The .dropdown container
  // eslint-disable-next-line no-undef
  const $container = $(e.target);

  // Find the actual .dropdown-menu
  let $dropdown = $container.find(".dropdown-menu");
  if ($dropdown.length) {
    // Save a reference to it, so we can find it after we've attached it to the body
    $container.data("dropdown-menu", $dropdown);
  } else {
    $dropdown = $container.data("dropdown-menu");
  }

  $dropdown.css(
    "top",
    `${$container.offset().top + $container.outerHeight()}px`
  );
  $dropdown.css("left", `${$container.offset().left}px`);
  $dropdown.css("position", "absolute");
  $dropdown.css("display", "block");
  $dropdown.appendTo("body");
});

// eslint-disable-next-line no-undef
$(document).on("hide.bs.dropdown", ".table-responsive", (e) => {
  // Hide the dropdown menu bound to this button
  // eslint-disable-next-line no-undef
  const target = $(e.target).data("dropdown-menu");
  if (target) {
    target.css("display", "none");
  }
});
