<template>
  <div
    v-if="trackUrl"
    class="block mb-0 pos-fix w-100 p-2 pr-3 pl-3"
    style="bottom: 0; z-index: 2000; left: 0"
  >
    <div
      class="d-flex justify-content-between mb-0 mb-md-2 pt-1 pb-1 pt-md-0 pb-md-0"
    >
      <div class="align-self-center" />
      <div v-if="minimized" class="pl-3 pr-3 plyr__title align-self-center">
        {{ name }}
      </div>
      <div class="ml-auto">
        <button
          v-if="minimized"
          class="btn btn-sm btn-light"
          @click="minimized = false"
        >
          Open player
        </button>
        <button v-else class="btn btn-sm btn-white" @click="minimized = true">
          Minimize player
        </button>
      </div>
    </div>
    <iframe
      :id="id"
      :src="trackUrl"
      :class="{
        'player-minimized': minimized,
      }"
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
      class="no-border player-max-height"
      style="width: 100%"
    />
  </div>
</template>

<script>
export default {
  name: "SpotifyPlayer",
  data() {
    return {
      id: "SpotifyPlayer",
      minimized: false,
    };
  },
  computed: {
    trackUrl() {
      return this.urlAndOptions(this.$store.state.player.trackUrl);
    },
    name() {
      return this.$store.state.player.name;
    },
  },
  methods: {
    urlAndOptions(url) {
      const spotifyUrl = url && url.includes(":") ? url.split(":") : [];
      if (spotifyUrl.length === 3) {
        return `https://open.spotify.com/embed/track/${spotifyUrl.pop()}`;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.player-max-height {
  max-height: 80px;
}
@media screen and (max-width: 768px) {
  .player-max-height {
    max-height: 100%;
    min-height: 60vh;
    height: 100%;
  }
}

.plyr__title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.player-minimized {
  max-height: 0 !important;
  height: 0 !important;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
</style>
