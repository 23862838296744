import store from "@/store";
import {
  ADMIN_MAIN_PAGE,
  BACKOFFICE_MAIN_PAGE,
  CREATIVE_MAIN_PAGE,
  FINANCE_DASHBOARD,
  DEMOBOX_MAIN_PAGE,
  LICENSING_MAIN_PAGE,
  LICENSING_MANAGER_MAIN_PAGE,
  DATA_ENTRY_MAIN,
  SALES_DEALS_RECORDS,
  CREATIVE_PROJECT_PAGE,
  RESOURCE,
} from "../../routes/const";
import ROLES from "../../const/Roles";

export default (account, currentLabel) => {
  const { permissions, isRightholder } = account;

  if (permissions.label_managers.includes(currentLabel)) {
    return ADMIN_MAIN_PAGE;
  }

  if (permissions.creative.includes(currentLabel)) {
    return CREATIVE_MAIN_PAGE;
  }

  if (permissions.catalog.includes(currentLabel)) {
    return RESOURCE.backoffice.nested.catalog;
  }

  if (permissions.demobox.includes(currentLabel)) {
    return DEMOBOX_MAIN_PAGE;
  }

  if (permissions.creative_project.includes(currentLabel)) {
    return CREATIVE_PROJECT_PAGE;
  }

  if (permissions.back_office.includes(currentLabel)) {
    return BACKOFFICE_MAIN_PAGE;
  }

  if (isRightholder || permissions.finance.includes(currentLabel)) {
    return FINANCE_DASHBOARD;
  }

  if (permissions.labels_manager_licensing.includes(currentLabel)) {
    return LICENSING_MANAGER_MAIN_PAGE;
  }

  if (permissions.licensing.includes(currentLabel)) {
    return LICENSING_MAIN_PAGE;
  }

  if (account.roles.includes(ROLES.DATA_ENTRY)) {
    return DATA_ENTRY_MAIN;
  }

  if (account.roles.includes(ROLES.SALES)) {
    return SALES_DEALS_RECORDS;
  }

  console.error(
    "Home page is not defined logout or permissions is not correct"
  );
  store.dispatch("auth/logout");

  return "login.index";
};
