/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

export default {
  currentLabel(state) {
    return state.currentLabel;
  },
  currentSubLabels(state) {
    const currentLabel = state.labels.filter(
      (label) => label.id === state.currentLabel
    )[0];
    return currentLabel && Array.isArray(currentLabel.sublabels)
      ? currentLabel.sublabels
      : [];
  },
  currentLabelData(state) {
    const curLabel = state.labels.find(
      (label) => label.id === state.currentLabel
    );
    return (
      curLabel || {
        accounting_till: null,
        id: null,
        logo: null,
        name: null,
        notifications: {},
        path: null,
        sublabels: [],
      }
    );
  },
  currentUser(state) {
    return {
      id: state.id,
      identifier: state.identifier,
      name: `${state.firstName} ${state.lastName}`,
    };
  },
  currentLabelName(state) {
    if (!state.labels) {
      return "";
    }
    const label = state.labels.find((label) => label.id === state.currentLabel);
    return label ? label.name : "";
  },
  labels(state) {
    return state.labels;
  },
  releaseManagers(state) {
    return state.releaseManagers;
  },
  assignedUsersInDeal(state) {
    return state.assignedUsersInDeal;
  },
};
