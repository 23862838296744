import BbProxy from "@/proxies/BbProxy";
import { appVars } from "@/const/AppVars";

class DealProxy extends BbProxy {
  constructor() {
    if (appVars.IsMockDataEnabled) {
      super("//my-json-server.typicode.com/dimetriynik/mockups/deals");
    } else {
      super("app/sales/deals");
    }
  }

  /**
   * all comments of deal
   * @param id - deal_id
   */
  relatedComments(id) {
    return this.submit("get", `app/deals/${id}/comments`);
  }

  /**
   * send request from form "add new deal"
   * @param formData - name, product, territory, pipeline_id, assigned_to
   */
  addNewDeal(formData) {
    return this.submit("post", "app/deals", formData);
  }

  /**
   * get users for "add new deal"
   */
  getUsersUssignetTo() {
    return this.submit("get", "app/sales/users");
  }

  update(id, formData) {
    return this.submit("put", `/${this.endpoint}/${id}`, formData);
  }
}

export default DealProxy;
