import BbProxy from "@/proxies/BbProxy";
import { DEMO_TYPE_SECONDARY as SECONDARY } from "@/const/labels";
/**
 * Sample data for update demo
 *
 * action should be sign or
 *
 * @type {{filter: string, action: string, demo_id: integer, label: string, category: string, skip_notification: boolean}}
 */
const SampleData = {
  demo_id: 0,
  action: "",
  label: "",
  category: "inbox",
  filter: "a",
  skip_notification: false,
};

class DemoPlusMinusProxy extends BbProxy {
  constructor(label, type) {
    const filter = type === SECONDARY ? "secondary" : "inbox";
    super(`${label}/demo/${filter}/inbox/a`);
    // super('catalog');
  }

  /**
   * Method used to update an item.
   * @param {int}    id   The given identifier.
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(id = 0, item) {
    item = { ...SampleData, ...item };
    return this.submit("post", `/${this.endpoint}`, item);
  }
}

export default DemoPlusMinusProxy;
