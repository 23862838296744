import * as types from "./mutation-types";

export const track = ({ commit }, data) => {
  if (data.url) {
    commit(types.TRACK, data);
  } else {
    console.error("global track url not set");
  }
};

export const run = ({ commit, state }, status) => {
  commit(types.RUN, status || !state.play);
};

export default {
  track,
  run,
};
