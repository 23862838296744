import BbProxy from "@/proxies/BbProxy";

class DemoSetRejected extends BbProxy {
  constructor(identifier) {
    super(`app/creative/demos/actions/reject/${identifier}`);
  }

  reject(skipNotification) {
    const formData = new FormData();
    formData.append("skip_notification", skipNotification);
    return this.submit("post", `/${this.endpoint}/`, formData);
  }

  rejectWithData(data) {
    return this.submit("post", `/${this.endpoint}/`, data);
  }
}
export default DemoSetRejected;
