import moment from "moment";
import Transformer from "../Transformer";

export default class WitdrawTransformer extends Transformer {
  /**
   *
   * @param lastWithdraw
   * @returns {boolean}
   */
  static daysDiff(lastWithdraw) {
    const createdAt = moment(lastWithdraw);
    const dateNow = moment();
    return dateNow.diff(createdAt, "days") > 30;
  }

  static fetch(withdraw) {
    return {
      isPaymentAvailable: this.daysDiff(withdraw.created_at),
      status: withdraw.status,
      amount: withdraw.amount,
      createdAt: withdraw.created_at,
    };
  }
}
