import Axios from "axios";

export default {
  /**
   * The methods that the mixin will add.
   */
  submit(requestType, url, data = null) {
    const { config } = this;

    return new Promise((resolve, reject) => {
      const Request = Axios.create({
        withCredentials: false,
        ...config,
      });
      Request[requestType](`${url}${this.getParameterString()}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject();
          }
        });
    });
  },
};
