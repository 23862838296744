const ROLES = {
  ADMIN: "Admin",
  RIGHTHOLDER: "rightholder",
  RIGHTHOLDER_MANAGER: "rightholder_manager",
  RIGHTHOLDER_STATEMENT_MANAGER: "rightholder_statement_manager", // permission to manage rightholder statement
  GUEST: "guest",
  RIGHTHOLDER_ADMIN: "finance_admin",
  DATA_ENTRY: "data_entry",
  FINANCE_UPDATE_ROLE: "finance_update_role",
  SALES: "sales",
  AR: "a&amp;r",
  ProjectBoard: "project_board",
  TASK_BOARD: "task_board",
  LABEL_ADMIN: "label_admin", // has any creative,backoffice... access to module
};
export default ROLES;
