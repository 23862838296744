import {
  TITLE_STATUSES,
  WITHDRAW_STATUS,
} from "@/const/FinanceWithdrawStatuses";
import WithdrawLastProxy from "@/proxies/finance/WithdrawLastProxy";
import { API_PARAM_LABEL_ID } from "@/const/labels";
import WithdrawTransformer from "@/transformers/financial/WithdrawTransformer";
import * as types from "./mutation-types";

export const setTotalTracks = ({ commit }, count) => {
  commit(types.SET_TOTAL_TRACKS, count);
};

export const setTotalShops = ({ commit }, count) => {
  commit(types.SET_TOTAL_SHOPS, count);
};

export const setTotalCountries = ({ commit }, count) => {
  commit(types.SET_TOTAL_COUNTRIES, count);
};

export const setTotalRightholders = ({ commit }, count) => {
  commit(types.SET_TOTAL_RIGHTHOLDERS, count);
};

export const setTotalTransactions = ({ commit }, count) => {
  commit(types.SET_TOTAL_TRANSACTIONS, count);
};

export const setWithDrawStatus = ({ commit }, count) => {
  commit(types.SET_WITHDRAW_STATUS, count);
};

export const setWithDrawInfo = ({ commit, rootState }) => {
  commit(types.WITHDRAW_LOADING, true);
  return new Promise((resolve, reject) => {
    const lastWithdraw = new WithdrawLastProxy();
    lastWithdraw.setParameter(
      API_PARAM_LABEL_ID,
      rootState.account.currentLabel
    );

    lastWithdraw
      .all()
      .then((data) => {
        const withdrawData = WithdrawTransformer.fetch(data.withdraw);
        const { status, isPaymentAvailable } = withdrawData;
        const positiveBalance = rootState.rightholder.balancePayable > 0;

        const defaultWithdraw = {
          class: "btn-primary",
          title: TITLE_STATUSES.WITHDRAW,
        };

        const negativeBalanceWithdraw = {
          class: "btn-white",
          title: TITLE_STATUSES.NEGATIVE_BALANCE,
        };

        const requiredWidthdraw = {
          class: "btn-light",
          title: TITLE_STATUSES.REQUIRED,
        };

        const approvalWithdraw = {
          class: "btn-danger",
          title: TITLE_STATUSES.APPROVED,
        };

        const paidWithdraw = {
          class: "btn-light",
          title: TITLE_STATUSES.PAID,
        };

        let WithdrawObject = defaultWithdraw;

        if (status !== null && positiveBalance) {
          if (status === WITHDRAW_STATUS.REQUIRED) {
            WithdrawObject = requiredWidthdraw;
          } else if (status === WITHDRAW_STATUS.APPROVED) {
            WithdrawObject = approvalWithdraw;
          } else if (status === WITHDRAW_STATUS.PAID && !isPaymentAvailable) {
            WithdrawObject = paidWithdraw;
          }
        } else if (!positiveBalance) {
          WithdrawObject = negativeBalanceWithdraw;
        }

        // eslint-disable-next-line no-param-reassign
        withdrawData.modalTitle = WithdrawObject.title;
        // eslint-disable-next-line no-param-reassign
        withdrawData.btnClass = WithdrawObject.class;

        commit(types.SET_WITHDRAW_INFO, withdrawData);
        resolve(withdrawData);
      })
      .catch((error) => {
        console.error("Cannot load data");
        reject(error);
      })
      .finally(() => {
        commit(types.WITHDRAW_LOADING, false);
      });
  });
};
export const resetCounts = ({ commit }) => {
  // commit(types.SET_TOTAL_TRACKS, 0);
  // commit(types.SET_TOTAL_SHOPS, 0);
  // commit(types.SET_TOTAL_COUNTRIES, 0);
  // commit(types.SET_TOTAL_RIGHTHOLDERS, 0);
  // commit(types.SET_TOTAL_TRANSACTIONS, 0);
  commit(types.CLEAR_FINANCE, 0);
};

export default {
  setTotalTracks,
  setTotalShops,
  setTotalCountries,
  setTotalRightholders,
  setTotalTransactions,
  setWithDrawStatus,
  resetCounts,
  setWithDrawInfo,
};
