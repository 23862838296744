import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = [];

  rules.push({
    action: "manage",
    subject: RESOURCE.finance.downloadExcel,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.finance.main,
  });
  return rules;
};
