/* ============
 * Getters for the demobox module
 * ============
 *
 * The getters that are available on the
 * demobox module.
 */

import {
  APPROVED,
  DemoProcessedFilters,
  IN_PROCESS_FILTER,
  SIGNED,
  SIGNED_FILTER,
} from "../../../../const/DemoFilters";

export default {
  primaryRejectedCount(state) {
    return state.primaryRejected.count;
  },
  primaryDemoList(state) {
    return state.primaryDemoList;
  },
  primaryFilters(state) {
    const filters = JSON.parse(JSON.stringify(DemoProcessedFilters));
    state.primaryDemoList.forEach((demo) => {
      if (typeof demo.status === "string") {
        if (typeof filters[demo.status] === "undefined") {
          filters[IN_PROCESS_FILTER].count += 1;
        } else {
          filters[demo.status].count += 1;
        }
      }
    });
    filters[APPROVED].count = state.approvedDemos.length;
    filters[SIGNED].count = state.signedDemos.length;
    return filters;
  },

  secondaryDemoList(state) {
    return state.secondaryDemoList;
  },
  secondaryFilters(state) {
    const filters = JSON.parse(JSON.stringify(DemoProcessedFilters));
    state.secondaryDemoList.forEach((demo) => {
      if (typeof demo.status === "string" && demo.status !== SIGNED_FILTER) {
        if (typeof filters[demo.status] === "undefined") {
          filters[IN_PROCESS_FILTER].count += 1;
        } else {
          filters[demo.status].count += 1;
        }
      }
    });
    delete filters[APPROVED];
    filters[SIGNED].count = state.signedDemos.length;
    // filters[REJECTED_FILTER].count = state.secondaryRejected.count;
    return filters;
  },
};
