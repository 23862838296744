import Vue from "vue";
import CsrfProxy from "./CsrfProxy";

class BaseProxy {
  /**
   * The constructor of the BaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   * @param {Object} config The config for Axios.
   */
  constructor(endpoint, parameters = {}, config = {}) {
    this.endpoint = endpoint;
    this.parameters = parameters;
    this.parameters._ = Date.now();
    this.config = config;
    // TODO: remove csrf
    this.csrf = true;
  }

  /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameters(parameters) {
    Object.keys(parameters).forEach((key) => {
      this.parameters[key] = parameters[key];
    });

    return this;
  }

  /**
   * don't disable csrf)))
   * @returns {BaseProxy}
   */
  disableCsrf() {
    this.csrf = true;
    return this;
  }

  enableCsrf() {
    this.csrf = true;
    return this;
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameter(parameter, value) {
    this.parameters[parameter] = value;

    return this;
  }

  /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameters(parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter];
    });

    return this;
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameter(parameter) {
    delete this.parameters[parameter];

    return this;
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */

  submit(requestType, url, data = null) {
    const { config } = this;

    return new Promise((resolve, reject) => {
      Vue.$http[requestType](url + this.getParameterString(), data, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject();
          }
        });
    });
  }

  submitCsrf(requestType, url, data = null) {
    const { config } = this;

    const csrfClient = new CsrfProxy();
    return new Promise((resolve, reject) => {
      csrfClient
        // .getCSRF()
        .then((_csrf) => {
          if (data instanceof FormData) {
            data.append("_csrf", _csrf);
          } else if (data instanceof Object) {
            // eslint-disable-next-line no-param-reassign
            data = { ...data, _csrf };
          } else {
            // eslint-disable-next-line no-param-reassign
            data = new FormData();
            data.append("_csrf", _csrf);
          }
          if (requestType === "delete") {
            this.setParameter("_csrf", _csrf);
          }

          Vue.$http[requestType](url + this.getParameterString(), data, config)
            .then((response) => {
              resolve(response.data);
            })
            .catch(({ response }) => {
              if (response) {
                reject(response.data);
              } else {
                reject();
              }
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Method used to fetch all items from the API.
   *
   * @returns {Promise} The result in a promise.
   */
  all() {
    return this.submit("get", `/${this.endpoint}`);
  }

  /**
   * Method used to fetch a single item from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  find(id) {
    return this.submit("get", `/${this.endpoint}/${id}`);
  }

  /**
   * Method used to create an item.
   *
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  create(item) {
    return this.submit("post", `/${this.endpoint}`, item);
  }

  /**
   * Method used to update an item.
   *
   * @param {int}    id   The given identifier.
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  update(id, item) {
    return this.submit("patch", `/${this.endpoint}/${id}`, item);
  }

  /**
   * Method used to destroy an item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  destroy(id) {
    return this.submit("delete", `/${this.endpoint}/${id}`);
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString() {
    const keys = Object.keys(this.parameters);

    const parameterStrings = keys
      .filter((key) => !!this.parameters[key])
      .map((key) => `${key}=${this.parameters[key]}`);

    return parameterStrings.length === 0
      ? ""
      : `?${parameterStrings.join("&")}`;
  }

  allTracks() {
    return this.all()
      .then((data) => {
        let dataResponse = [];
        if (!data) {
          return dataResponse;
        }
        const dataLoop = data.demo ? data.demo : data;

        // TODO: remove this when the API is fixed
        if (Array.isArray(dataLoop)) {
          dataResponse = dataLoop.map((track) => {
            /**
             * checking if "file" exists
             */
            const { asset } = track;
            if (asset && Array.isArray(asset.file) && asset.file.length > 0) {
              const { _file0 } = track.asset.file[0];
              if (_file0.url) {
                return {
                  ...track,
                  asset: {
                    ...asset,
                    single_file: { _single_file: { url: _file0.url } },
                  },
                };
              }
            }

            return track;
          });
        } else {
          console.error("releases not correct");
        }
        return dataResponse;
      })
      .catch(() => {
        console.log("Cannot load account info by route");
      });
  }
}

export default BaseProxy;
