<template>
  <transition name="player-transition">
    <div
      :class="{
        'd-none': !trackUrlNew && !isSoundCloud && !isSpotifyIdentifier,
      }"
      class="block mb-0 pos-fix w-100 global-player-js"
      style="
        bottom: 0;
        z-index: 2000;
        left: 0;
        background-color: #232323 !important;
      "
      @click="event"
    >
      <div id="plyrist_audio" data-plugin="plyr" class="plyrist plyrist_audio">
        <audio controls hidden />
        <div v-if="playlistShow" class="plyr-list" style="display: none" />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
        <symbol id="plyr-airplay" viewBox="0 0 18 18">
          <path
            d="M16 1H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h3v-2H3V3h12v8h-2v2h3a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"
          />
          <path d="M4 17h10l-5-6z" />
        </symbol>
        <symbol id="plyr-captions-off" viewBox="0 0 18 18">
          <path
            d="M1 1c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h4.6l2.7 2.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.7-2.7H17c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1H1zm4.52 10.15c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41C8.47 4.96 7.46 3.76 5.5 3.76c-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69zm7.57 0c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41c-.28-1.15-1.29-2.35-3.25-2.35-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69z"
            fill-rule="evenodd"
            fill-opacity=".5"
          />
        </symbol>
        <symbol id="plyr-captions-on" viewBox="0 0 18 18">
          <path
            d="M1 1c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h4.6l2.7 2.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.7-2.7H17c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1H1zm4.52 10.15c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41C8.47 4.96 7.46 3.76 5.5 3.76c-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69zm7.57 0c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41c-.28-1.15-1.29-2.35-3.25-2.35-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69z"
            fill-rule="evenodd"
          />
        </symbol>
        <symbol id="plyr-enter-fullscreen" viewBox="0 0 18 18">
          <path
            d="M10 3h3.6l-4 4L11 8.4l4-4V8h2V1h-7zM7 9.6l-4 4V10H1v7h7v-2H4.4l4-4z"
          />
        </symbol>
        <symbol id="plyr-exit-fullscreen" viewBox="0 0 18 18">
          <path
            d="M1 12h3.6l-4 4L2 17.4l4-4V17h2v-7H1zM16 .6l-4 4V1h-2v7h7V6h-3.6l4-4z"
          />
        </symbol>
        <symbol id="plyr-fast-forward" viewBox="0 0 18 18">
          <path d="M7.875 7.171L0 1v16l7.875-6.171V17L18 9 7.875 1z" />
        </symbol>
        <symbol id="plyr-muted" viewBox="0 0 18 18">
          <path d="M5 7v4h3l4 4V3L8 7H5z" />
        </symbol>
        <symbol id="plyr-pause" viewBox="0 0 18 18">
          <path
            d="M6 1H3c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1zM12 1c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1h-3z"
          />
        </symbol>
        <symbol id="plyr-pip" viewBox="0 0 18 18">
          <path
            d="M13.293 3.293L7.022 9.564l1.414 1.414 6.271-6.271L17 7V1h-6z"
          />
          <path
            d="M13 15H3V5h5V3H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-6h-2v5z"
          />
        </symbol>
        <symbol id="plyr-play" viewBox="0 0 18 18">
          <path
            d="M16.562 8.1l-11.692-7.875c-0.818-0.562-1.87 0-1.87 0.9v15.75c0 0.9 1.052 1.462 1.87 0.9l11.693-7.875c0.584-0.45 0.584-1.35 0-1.8z"
          />
        </symbol>
        <symbol id="plyr-restart" viewBox="0 0 18 18">
          <path
            d="M9.7 1.2l.7 6.4 2.1-2.1c1.9 1.9 1.9 5.1 0 7-.9 1-2.2 1.5-3.5 1.5-1.3 0-2.6-.5-3.5-1.5-1.9-1.9-1.9-5.1 0-7 .6-.6 1.4-1.1 2.3-1.3l-.6-1.9C6 2.6 4.9 3.2 4 4.1 1.3 6.8 1.3 11.2 4 14c1.3 1.3 3.1 2 4.9 2 1.9 0 3.6-.7 4.9-2 2.7-2.7 2.7-7.1 0-9.9L16 1.9l-6.3-.7z"
          />
        </symbol>
        <symbol id="plyr-rewind" viewBox="0 0 18 18">
          <path d="M10.125 1L0 9l10.125 8v-6.171L18 17V1l-7.875 6.171z" />
        </symbol>
        <symbol id="plyr-settings" viewBox="0 0 18 18">
          <path
            d="M16.135 7.784a2 2 0 0 1-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094a2 2 0 0 1-2.969-1.23C10.065 1.258 9.669 1 9.219 1h-.438c-.45 0-.845.258-.997.865a2 2 0 0 1-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 0 1-1.23 2.969C1.26 7.935 1 8.33 1 8.781v.438c0 .45.258.845.865.997a2 2 0 0 1 1.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 0 1 2.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 0 1 2.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.781.094-1.316a2 2 0 0 1 1.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997zM9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
          />
        </symbol>
        <symbol id="plyr-volume" viewBox="0 0 18 18">
          <path
            d="M12.79 9c0-1.3-.72-2.42-1.79-3v6c1.06-.58 1.79-1.7 1.79-3zM2 7v4h3l4 4V3L5 7H2zm9-5v1.5c2.32.74 4 2.93 4 5.5s-1.68 4.76-4 5.5V16c3.15-.78 5.5-3.6 5.5-7S14.15 2.78 11 2z"
          />
        </symbol>
        <symbol id="plyr-prev" viewBox="0 0 24 24">
          <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
        </symbol>
        <symbol id="plyr-next" viewBox="0 0 24 24">
          <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
        </symbol>
        <symbol id="plyr-shuffle" viewBox="0 0 24 24">
          <path
            d="M10.59 9.17L5.41 4 4 5.41l5.17 5.17 1.42-1.41zM14.5 4l2.04 2.04L4 18.59 5.41 20 17.96 7.46 20 9.5V4h-5.5zm.33 9.41l-1.41 1.41 3.13 3.13L14.5 20H20v-5.5l-2.04 2.04-3.13-3.13z"
          />
        </symbol>
        <symbol id="plyr-repeat" viewBox="0 0 24 24">
          <path
            d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z"
          />
        </symbol>
        <symbol id="plyr-list" viewBox="0 0 24 24">
          <path
            d="M15 6H3v2h12V6zm0 4H3v2h12v-2zM3 16h8v-2H3v2zM17 6v8.18c-.31-.11-.65-.18-1-.18-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3V8h3V6h-5z"
          />
        </symbol>
        <symbol id="plyr-like" viewBox="0 0 24 24">
          <path
            d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
          />
        </symbol>
        <symbol id="plyr-liked" viewBox="0 0 24 24">
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        </symbol>
      </svg>
    </div>
  </transition>
</template>
<script>
import "plyr/dist/plyr.css";
import "../../../assets/js/libs/plyrist/src/plyrist";
import { OPEN_ASIDE } from "../../../store/modules/player/mutation-types";
import { MODAL_OPEN_LICENSING } from "../../../store/modules/eventBus/mutation-types";

export default {
  name: "AudioPlayer",

  data: () => ({
    player: {},
    playlistShow: true,
    PlayPauseButton: false,
  }),
  computed: {
    trackUrlNew() {
      return this.$store.state.player.trackUrl;
    },
    play() {
      return this.$store.state.player.play;
    },
    poster() {
      return this.$store.state.player.poster;
    },
    name() {
      return this.$store.state.player.name;
    },
    waveUrl() {
      return this.$store.state.player.waveUrl;
    },
    bgTheme() {
      return this.$store.state.setting.bgColor;
    },
    isSoundCloud() {
      return this.$store.state.player.isSoundCloud;
    },
    isLicensing() {
      return this.$store.state.player.isLicensing;
    },
    isSpotifyIdentifier() {
      const { trackUrl } = this.$store.state.player;
      return trackUrl && trackUrl.includes("spotify:");
    },
    trackData() {
      return this.$store.state.player.trackData;
    },
  },
  watch: {
    trackUrlNew(newValue) {
      this.player.player.source = {
        type: "audio",
        sources: [
          {
            src: newValue,
          },
        ],
      };
      this.player.player.play();
      this.player.select({
        name: this.name,
        poster: this.poster,
        waveUrl: this.waveUrl,
      });
    },
    play(newValue) {
      if (!this.PlayPauseButton) {
        if (newValue) {
          this.player.player.play();
        } else {
          this.player.player.pause();
        }
      }

      this.PlayPauseButton = false;
    },
    isSoundCloud(newValue) {
      if (newValue) {
        this.player.player.pause();
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    $(this.$el).on("touchend", ".wave-click-area-js", (e) => {
      const el = $(this);
      const position = el.offset();
      const percentClick = (e.clientX - position.left) / el.width();
      const { duration } = this.player.player.media;
      this.player.player.media.currentTime = duration * percentClick;
    });
    if (window.Plyrist) {
      this.player = new window.Plyrist(
        {
          playlist: "#plyrist_audio",
          player: "audio",
        },
        [],
        {
          debug: false,
        }
      );
      this.player.player.on("play", () => {
        if (!this.play) {
          this.PlayPauseButton = true;
          this.$store.dispatch("player/run");
        }
      });
      this.player.player.on("pause", () => {
        if (this.play) {
          this.PlayPauseButton = true;
          this.$store.dispatch("player/run");
        }
      });
      this.player.player.on("timeupdate", (e) => {
        const { currentTime, duration } = e.detail.plyr.media;
        // eslint-disable-next-line no-undef
        $(this.$el)
          .find(".wave-spend-js")
          .stop(true, true)
          .animate(
            { width: `${((currentTime + 0.25) / duration) * 100}%` },
            250,
            "linear"
          );
      });
    }
  },
  beforeDestroy() {
    this.player.player.pause();
    this.player = {};
  },
  methods: {
    event(e) {
      // eslint-disable-next-line no-undef
      const { target } = e;
      const el = $(target);

      if (el.hasClass("wave-click-area-js")) {
        const position = el.offset();
        const percentClick = (e.clientX - position.left) / el.width();
        const { duration } = this.player.player.media;
        this.player.player.media.currentTime = duration * percentClick;
      }
      if (el.hasClass("plyr__title")) {
        if (this.isLicensing) {
          this.$store.commit(`eventBus/${MODAL_OPEN_LICENSING}`, {
            open: true,
            trackData: this.trackData,
          });
          return false;
        }
        this.$store.commit(`player/${OPEN_ASIDE}`);
      }
    },
  },
};
</script>
