<template>
  <div class="media-action">
    <button
      :class="{ active: active && play }"
      type="button"
      class="btn btn-raised btn-icon btn-rounded bg--white i-con-h-a"
      @click.stop="setGlobalPlay"
    >
      <i :class="playObjectClass" />
    </button>
  </div>
</template>

<script>
export default {
  name: "PlayButton",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    waveUrl: {
      type: String,
      default: "",
    },
    soundcloud: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    trackUrl() {
      return this.$store.state.player.trackUrl;
    },
    play() {
      return this.$store.state.player.play;
    },
    playObjectClass() {
      if (this.active) {
        return {
          "i-con": true,
          "i-con-play": !this.play,
          "i-con-pause": this.play,
        };
      }
      return {
        "i-con": true,
        "i-con-play": true,
      };
    },
  },

  methods: {
    setGlobalPlay() {
      if (this.trackUrl !== this.url) {
        const track = {
          url: this.url,
          name: this.name,
          poster: this.poster,
          waveUrl: this.waveUrl,
          trackData: this.item,
          isLicensing: this.item.isLicensing || false,
        };
        this.$store.dispatch("player/track", {
          ...track,
          isSoundCloud: this.soundcloud,
        });
      }

      if (this.active) {
        this.$store.dispatch("player/run", !this.play);
      }
    },
  },
};
</script>

<style scoped></style>
