import {
  CREATIVE_INSTRUMENTAL_APPROVED,
  CREATIVE_INSTRUMENTAL_CONCEPT,
  CREATIVE_INSTRUMENTAL_INBOX,
  CREATIVE_INSTRUMENTAL_REJECT,
  CREATIVE_INSTRUMENTAL_REQUESTED,
  CREATIVE_INSTRUMENTAL_SINGLE_PAGE,
  CREATIVE_PROJECT_CONVERT_PAGE,
  CREATIVE_PROJECT_SINGLE_PAGE,
  CREATIVE_TOPLINE_SINGLE_PAGE,
  DEMO_LICENSING_SINGLE_PAGE,
  LICENSING_APPROVED_PAGE,
  LICENSING_DASHBOARD_PAGE,
  LICENSING_PRIMARY_PAGE,
  LICENSING_SECONDARY_PAGE,
  LICENSING_UNASSIGNED_PAGE,
  RESOURCE,
} from "../const";
import setLabelBeforeEnter from "../setLabelBeforeEnter";

export default {
  path: "/creative",
  name: "creative.index",
  component: () => import("@/views/Creative/Index.vue"),
  redirect: "/creative/dashboard",

  children: [
    {
      path: "dashboard",
      component: () => import("@/views/Creative/Dashboard/Index.vue"),
      name: "creative.dashboard",
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.dashboard,
      },
    },
    {
      path: ":parentLabelPath/project/:identifier",
      component: () =>
        import("@/views/Creative/Projects/ProjectSinglePage.vue"),
      name: CREATIVE_PROJECT_SINGLE_PAGE,
      beforeEnter: setLabelBeforeEnter,
    },
    {
      path: ":parentLabelPath/topline/:identifier",
      component: () =>
        import("@/views/Creative/Toplines/pages/ToplineSinglePage.vue"),
      name: CREATIVE_TOPLINE_SINGLE_PAGE,
      beforeEnter: setLabelBeforeEnter,
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.toplinesConcept,
      },
    },
    {
      path: ":parentLabelPath/instrumental/:identifier",
      component: () =>
        import("@/views/Creative/Toplines/pages/InstrumentalSinglePage.vue"),
      name: CREATIVE_INSTRUMENTAL_SINGLE_PAGE,
      beforeEnter: setLabelBeforeEnter,
    },
    {
      path: ":parentLabelPath/project/convert/:identifier",
      component: () =>
        import("@/views/Creative/Projects/ConvertToTrackPage.vue"),
      name: CREATIVE_PROJECT_CONVERT_PAGE,
      beforeEnter: setLabelBeforeEnter,
    },
    {
      path: "project",
      component: () => import("@/views/Creative/Projects/Index.vue"),
      name: "creative.project",
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.projects,
      },
    },

    {
      path: "project-schedule",
      component: () => import("@/views/Creative/ProjectsSchedule/Index.vue"),
      name: "creative.projects-schedule",
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.projects,
      },
    },

    {
      path: "demobox",
      component: () => import("@/views/Creative/Demobox/Index.vue"),
      name: "creative.demobox",
      // redirect: '/creative/demobox/primary',
      children: [
        {
          path: "",
          redirect: "primary",
        },
        {
          path: "primary",
          component: () => import("@/views/Creative/Demobox/Primary/Index.vue"),
          name: "creative.demobox.primary",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.primary,
          },
        },
        {
          path: "secondary",
          component: () =>
            import("@/views/Creative/Demobox/Secondary/Index.vue"),
          name: "creative.demobox.secondary",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.primary,
          },
        },
        {
          path: "sign/:identifier",
          component: () => import("@/views/Creative/Demobox/Sign/Index.vue"),
          name: "creative.demobox.sign",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.primary,
          },
        },
        {
          path: "reject/:type/:identifier",
          component: () => import("@/views/Creative/Demobox/Reject/Index.vue"),
          name: "creative.demobox.reject",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.primary,
          },
        },
      ],
    },
    {
      path: "toplines",
      name: "creative.toplines",
      component: () => import("@/views/Creative/Demobox/Index.vue"),
      children: [
        {
          path: "",
          redirect: "backlog",
        },
        {
          path: "concept",
          component: () =>
            import("@/views/Creative/Toplines/pages/BackLog.vue"),
          name: RESOURCE.creative.nested.toplinesConcept,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesConcept,
          },
        },
        {
          path: "inbox",
          component: () =>
            import("@/views/Creative/Toplines/pages/ToplinesInbox.vue"),
          name: "creative.toplines.inbox",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesInbox,
          },
        },
        {
          path: "requested",
          component: () =>
            import("@/views/Creative/Toplines/pages/Requested.vue"),
          name: "creative.toplines.requested",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesRequested,
          },
        },
        {
          path: "approved",
          component: () =>
            import("@/views/Creative/Toplines/pages/Approved.vue"),
          name: "creative.toplines.approved",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesApproved,
          },
        },
        {
          path: "reject/:type/:identifier",
          component: () => import("@/views/Creative/Demobox/Reject/Index.vue"),
          name: "creative.toplines.reject",
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.primary,
          },
        },
      ],
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.projects,
      },
    },
    {
      path: "instrumental",
      name: "creative.instrumental",
      component: () => import("@/views/Creative/Demobox/Index.vue"),
      children: [
        {
          path: "concept",
          component: () =>
            import("@/views/Creative/Toplines/pages/Instrumentals.vue"),
          name: CREATIVE_INSTRUMENTAL_CONCEPT,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.instrumentalsConcept,
          },
        },
        {
          path: "inbox",
          component: () =>
            import("@/views/Creative/Toplines/pages/ToplinesInbox.vue"),
          name: CREATIVE_INSTRUMENTAL_INBOX,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesInbox,
          },
        },
        {
          path: "requested",
          component: () =>
            import("@/views/Creative/Toplines/pages/Requested.vue"),
          name: CREATIVE_INSTRUMENTAL_REQUESTED,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesRequested,
          },
        },
        {
          path: "approved",
          component: () =>
            import("@/views/Creative/Toplines/pages/Approved.vue"),
          name: CREATIVE_INSTRUMENTAL_APPROVED,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.toplinesApproved,
          },
        },
        {
          path: "reject/:type/:identifier",
          component: () => import("@/views/Creative/Demobox/Reject/Index.vue"),
          name: CREATIVE_INSTRUMENTAL_REJECT,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.primary,
          },
        },
      ],
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.instrumentalsConcept,
      },
    },

    {
      path: "licensing",
      component: () => import("@/views/Creative/Licensing/Index.vue"),
      name: "creative.licensing",
      redirect: "/creative/licensing/inbox",
      children: [
        {
          path: "",
          redirect: "licensing",
        },
        {
          path: "dashboard",
          component: () =>
            import("@/views/Creative/Licensing/Dashboard/Index.vue"),
          name: LICENSING_DASHBOARD_PAGE,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.licensingDashboard,
          },
        },
        {
          path: "unassigned",
          component: () =>
            import("@/views/Creative/Licensing/Unassigned/Index.vue"),
          name: LICENSING_UNASSIGNED_PAGE,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.unassigned,
          },
        },
        {
          path: "primary",
          component: () =>
            import("@/views/Creative/Licensing/Primary/Index.vue"),
          name: LICENSING_PRIMARY_PAGE,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.licensingPrimary,
          },
        },
        {
          path: "secondary",
          component: () =>
            import("@/views/Creative/Licensing/Secondary/Index.vue"),
          name: LICENSING_SECONDARY_PAGE,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.licensingSecondary,
          },
        },
        {
          path: "approved",
          component: () =>
            import("@/views/Creative/Licensing/Approved/Index.vue"),
          name: LICENSING_APPROVED_PAGE,
          meta: {
            auth: true,
            resource: RESOURCE.creative.nested.licensingSecondary,
          },
        },
        {
          path: "page/:identifier",
          component: () =>
            import("@/views/Creative/Licensing/SinglePage/Index.vue"),
          name: DEMO_LICENSING_SINGLE_PAGE,
          meta: {
            auth: true,
            action: "auth",
            resource: RESOURCE.loggedIn,
          },
        },
      ],
    },
  ],
  // If the user needs to be authenticated to view this page.
  meta: {
    auth: true,
    resource: RESOURCE.creative.main,
  },
};
