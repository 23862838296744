import Vue from "vue";
import { SET_CURRENT_LABEL } from "../modules/account/mutation-types";

export default (store) =>
  // eslint-disable-next-line prefer-destructuring

  store.subscribe((mutation, state) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case `account/${SET_CURRENT_LABEL}`: {
        const { route, account } = state;
        if (
          state.account.currentLabel &&
          route.meta &&
          Vue.can &&
          !Vue.can(route.meta.action || "manage", route.meta.resource)
        ) {
          console.warn("Permission denied redirect to home page");
          Vue.router.push({
            name: account.homepage,
          });
        }
        break;
      }
    }
  });
