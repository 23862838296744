/*
 * Hardcoded names names
 * */

export const ALL = "All";
export const DEMO_TYPE_PRIMARY = "primary";
export const DEMO_TYPE_SECONDARY = "secondary";
export const DEMO_TYPE_BACKLOG = "backlog";
export const DEMO_TYPE_UNASSIGNED = "unassigned";

export const API_PARAM_LABEL_ID = "label_id";
export const API_PARAM_CURRENT_MONTH = "month";
export const API_PARAM_SHOP = "shop";

export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";

export default {
  ORDER_DESC,
  ORDER_ASC,
  API_PARAM_SHOP,
  API_PARAM_CURRENT_MONTH,
  API_PARAM_LABEL_ID,
  DEMO_TYPE_UNASSIGNED,
  DEMO_TYPE_BACKLOG,
  DEMO_TYPE_SECONDARY,
  DEMO_TYPE_PRIMARY,
  ALL,
};
