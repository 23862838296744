import ROLES from "../../../../const/Roles";
import { RESOURCE } from "../../../../routes/const";

export default (account, currentLabel) => {
  const {
    roles,
    permissions: {
      label_managers: labelManagers,
      creative,
      creative_project: creativeProject,
    },
  } = account;
  const rules = [];

  if (
    (Array.isArray(roles) &&
      roles
        .map((role) => role.toLowerCase())
        .includes(ROLES.SALES.toLowerCase())) ||
    creative.includes(currentLabel) ||
    creativeProject.includes(currentLabel) ||
    labelManagers.includes(currentLabel) ||
    (Array.isArray(roles) &&
      roles.map((role) => role.toLowerCase()).includes(ROLES.ProjectBoard))
  ) {
    rules.push({
      action: "manage",
      subject: RESOURCE.generalTasks,
    });
  }

  return rules;
};
