import { RESOURCE } from "../../../../routes/const";
import ROLES from "../../../../const/Roles";

export default (account) => {
  const { roles } = account;
  const rules = [];
  if (Array.isArray(roles) && roles.includes(ROLES.DATA_ENTRY)) {
    rules.push({
      action: "manage",
      subject: RESOURCE.dataEntry.main,
    });
  }
  return rules;
};
