import BbProxy from "@/proxies/BbProxy";

class DemoChangeLabelProxy extends BbProxy {
  constructor() {
    super("app/creative/demos/actions/set/label");
  }

  setLabel(identifier, newLabelId) {
    const formData = new FormData();
    formData.append("new_label_id", newLabelId);
    formData.append("identifier", identifier);
    return this.submit("post", `/${this.endpoint}/`, formData);
  }
}

export default DemoChangeLabelProxy;
