export const SET_TOTAL_TRACKS = "SET_TOTAL_TRACKS";
export const SET_TOTAL_SHOPS = "SET_TOTAL_SHOPS";
export const SET_TOTAL_COUNTRIES = "SET_TOTAL_COUNTRIES";
export const SET_TOTAL_RIGHTHOLDERS = "SET_TOTAL_RIGHTHOLDERS";
export const SET_TOTAL_TRANSACTIONS = "SET_TOTAL_TRANSACTIONS";
export const SET_WITHDRAW_STATUS = "SET_WITHDRAW_STATUS";
export const SET_WITHDRAW_INFO = "SET_WITHDRAW_INFO";
export const WITHDRAW_LOADING = "WITHDRAW_LOADING";
export const CLEAR_FINANCE = "CLEAR_FINANCE";

export default {
  SET_TOTAL_TRACKS,
  SET_TOTAL_SHOPS,
  SET_TOTAL_COUNTRIES,
  SET_TOTAL_RIGHTHOLDERS,
  SET_TOTAL_TRANSACTIONS,
  SET_WITHDRAW_STATUS,
  CLEAR_FINANCE,
  SET_WITHDRAW_INFO,
  WITHDRAW_LOADING,
};
