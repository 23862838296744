import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = [];

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.creativeInboxModule,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.toplinesConcept,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.instrumentalsRequested,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.instrumentalsApproved,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.instrumentalsInbox,
  });

  return rules;
};
