/* ============
 * State of the module rightholder
 * ============
 *
 * The initial state of the rightholder module.
 */

export default {
  rightholderStatements: [],
  isTotalGeneratingInProcess: false,
};
