export const WITHDRAW_STATUS = {
  REQUIRED: "Requested",
  APPROVED: "Approved",
  PAID: "Paid",
};

export const TITLE_STATUSES = {
  WITHDRAW: "Withdraw balance",
  REQUIRED: "Payment requested",
  APPROVED: "Payment info required",
  PAID: "Balance Paid",
  NEGATIVE_BALANCE: "Balance insufficient",
};
