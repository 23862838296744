import BbProxy from "@/proxies/BbProxy";

class LabelsProxy extends BbProxy {
  /**
   * The constructor for the LabelsProxy.
   *
   * @param  identifier {string} parameters The query parameters.
   */
  constructor(identifier) {
    super(`app/account/rightholder-labels/${identifier}`);
  }
}

export default LabelsProxy;
