/* ============
 * State of the module rightholder
 * ============
 *
 * The initial state of the rightholder module.
 */

export default {
  balancePayable: 0,
  totalCosts: 0,
  netIncome: 0,
  payouts: 0,
  userId: null,
  userIdentifier: null,
};
