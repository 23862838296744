export const IN_PROCESS_FILTER = "InProcess";
export const SIGNED_FILTER = "Signed";
export const REJECTED_FILTER = "Rejected";
export const STARRED_FILTER = "Starred";
export const APPROVED = "Approved";
export const SIGNED = "Signed";
export const SNOOZED = "Snoozed";
export const ARCHIVED = "Archived";

export const DemoAssetsFilters = {
  All: { count: 0, name: "All" },
  Demo: { count: 0, name: "Demo's" },
  Topline: { count: 0, name: "Toplines" },
  Instrumental: { count: 0, name: "Instrumentals" },
  Bootleg: { count: 0, name: "Bootleg/Mashups" },
  Remix: { count: 0, name: "Remixes" },
};
export const DemoProcessedFilters = {
  [IN_PROCESS_FILTER]: { count: 0, name: "In Process" },
  [SNOOZED]: { count: 0, name: SNOOZED },
  [REJECTED_FILTER]: { count: 0, name: "Rejected" },
  [APPROVED]: { count: 0, name: APPROVED },
  [SIGNED]: { count: 0, name: SIGNED },
};

export const LicensingFilters = {
  [IN_PROCESS_FILTER]: { count: 0, name: "In Process" },
  [SNOOZED]: { count: 0, name: "Snoozed" },
  [REJECTED_FILTER]: { count: 0, name: "Rejected" },
};
