import store from "../store";

export default (to, from, next) => {
  const { parentLabelPath } = to.params;
  const { account } = store.state;
  const { labels: userLabels } = account;
  const { currentLabel } = account;
  const releaseParentLabel = userLabels.find(
    (label) => label.path === parentLabelPath
  );
  if (releaseParentLabel && releaseParentLabel.id !== currentLabel) {
    store.dispatch("account/setCurrentLabel", releaseParentLabel.id);
  }
  next();
};
