import { RESOURCE } from "@/routes/const";

export default () => {
  const rules = [];

  rules.push({
    action: "manage",
    subject: RESOURCE.briefing.main,
  });
  return rules;
};
