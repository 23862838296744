import BbProxy from "@/proxies/BbProxy";

// TODO merge this with DemoSetRejected.js proxy, this is FAST hard fix
class DemoSetBlackListArtist extends BbProxy {
  constructor(identifier) {
    super(`app/creative/demos/actions/blacklist/${identifier}`);
  }

  blacklist(skipNotification) {
    const formData = new FormData();
    formData.append("skip_notification", skipNotification);
    return this.submit("post", `/${this.endpoint}/`, formData);
  }
}
export default DemoSetBlackListArtist;
