<template>
  <div class="licensing__modal_card p-3 mb-4 card">
    <h5 class="pb-4">Data</h5>
    <div class="text-muted text-sm">
      <h6>Watching status</h6>
    </div>
    <SelectField
      v-show="!isLoadingField.watching_status"
      :value="fieldValue"
      :default-value="'Select status'"
      :options="['yes', 'no']"
      :_width="'100%'"
      @changed="(value) => updateStatus(value)"
    />
    <div v-if="isLoadingField.watching_status" style="min-height: 36px">
      <LoadingGrow />
    </div>
  </div>
</template>

<script>
import SelectField from "@/views/components/Table/Fields/SelectField.vue";
import LoadingGrow from "@/components/loaders/Grow.vue";
import PlaylistProxy from "@/proxies/PlaylistProxy";

export default {
  name: "LicensingTrackData",
  components: { LoadingGrow, SelectField },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingField: { watching_status: false },
      follow: null,
    };
  },
  computed: {
    fieldValue() {
      return this.follow ? this.follow : this.track.pl_track_follow;
    },
  },
  methods: {
    updateStatus(value) {
      this.isLoadingField.watching_status = true;
      new PlaylistProxy()
        .setFollowStatus(this.track.playlist_track_id, value)
        .then(() => {
          this.follow = value;
          this.$appNotification().success("Field updated");
        })
        .catch(() => {
          this.$appNotification().error("Error during update");
        })
        .finally(() => {
          this.isLoadingField.watching_status = false;
        });
    },
  },
};
</script>
<style scoped>
.card {
  background-color: #212121;
  border-width: 0;
  padding: 1.5rem !important;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05),
    0 -1px 0px rgba(0, 0, 0, 0.015);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 -1px 0px rgba(0, 0, 0, 0.015);
}
</style>
