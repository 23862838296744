import BbProxy from "@/proxies/BbProxy";

class LineChartDataProxy extends BbProxy {
  constructor() {
    super("app/creative/licensing/streams");
  }

  getData(demoIdentifier) {
    return this.submit("get", `${this.endpoint}/${demoIdentifier}`);
  }
}

export default LineChartDataProxy;
