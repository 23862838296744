import { RESOURCE } from "../const";

export default {
  path: "/:pathMatch(.*)*",
  name: "404",
  meta: {
    action: "view",
    resource: RESOURCE.publicPages,
  },
  // redirect: '/creative',
  component: () => import("@/views/404/Index.vue"),
};
