import getDefaultAccountState from "./default";
/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

const state = getDefaultAccountState();
export default state;
