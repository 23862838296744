import { RESOURCE, GENERAL_TASKS, GENERAL_PROJECT_BOARD } from "@/routes/const";

export default {
  path: "/general",
  name: "general.index",
  redirect: "/general",
  component: () => import("@/views/General/Index.vue"),
  children: [
    {
      path: "tasks",
      component: () => import("@/views/General/page/TasksGeneral.vue"),
      name: GENERAL_TASKS,
      meta: {
        auth: true,
        resource: RESOURCE.generalTasks,
      },
    },
    {
      path: "project-board",
      name: GENERAL_PROJECT_BOARD,
      component: () => import("@/views/General/page/ProjectBoardGeneral.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.projectBoard,
      },
    },
  ],
};
