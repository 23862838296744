/* ============
 * Mutation types for the demobox module
 * ============
 *
 * The mutation types that are available
 * on the demobox module.
 */

export const SET_TOPLINES_LIST = "SET_TOPLINES_LIST";

export const SET_SECONDARY_DEMO_LIST = "SET_SECONDARY_DEMO_LIST";

export const REMOVE_DEMO_FROM_LIST = "REMOVE_DEMO_FROM_LIST";
export const ADD_DEMO_TO_LIST = "ADD_DEMO_TO_LIST";

export default {
  SET_TOPLINES_LIST,
};
