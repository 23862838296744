import BbProxy from "@/proxies/BbProxy";

class ReleaseStatusesProxy extends BbProxy {
  /**
   * The constructor for the ArtistProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("app/data/release/statuses", parameters);
  }
}

export default ReleaseStatusesProxy;
