export default {
  totalTracks: 0,
  totalShops: 0,
  totalCountries: 0,
  totalRightholders: 0,
  totalTransactions: 0,
  withDrawStatus: null,
  withDraw: {
    isLoading: true,
    isPaymentAvailable: false,
    status: null,
    amount: null,
    createdAt: null,
    modal: {
      title: null,
    },
    button: {
      cssClass: null,
    },
  },
};
