/* ============
 * Actions for the demobox module
 * ============
 *
 * The actions that are available on the
 * demobox module.
 */

import ToplinesProxy from "@/proxies/creative/ToplinesProxy";
import DemoPlusMinusProxy from "@/proxies/creative/demobox/DemoPlusMinusProxy";
import { API_PARAM_LABEL_ID } from "@/const/labels";
import * as types from "./mutation-types";

export const fetchAllTracks = ({ commit, rootState }) => {
  const client = new ToplinesProxy();
  if (rootState.account.currentLabel) {
    client.setParameter(API_PARAM_LABEL_ID, rootState.account.currentLabel);
  }
  client
    .getAllTracks()
    .then((response) => {
      commit(types.SET_TOPLINES_LIST, response.toplines);
    })
    .catch(() => {
      console.log("Cannot fetchAllPrimaryTracks info!");
      // TODO: maybe add call to analytics or debug app?
    });
};
//
// export const fetchAllSecondaryTracks = ({ commit, rootState }) => {
//   const client = new DemoSecondaryProxy();
//   if (rootState.account.currentLabel) {
//     client.setParameter(API_PARAM_LABEL_ID, rootState.account.currentLabel);
//   }
//   client.getAllTracks()
//     .then((response) => {
//       commit(types.SET_SECONDARY_DEMO_LIST, response.demo);
//     })
//     .catch(() => {
//       console.log('Cannot fetchAllSecondaryTracks info!');
//       // TODO: maybe add call to analytics or debug app?
//     });
// };
//
export const clickPlusOnDemo = (
  { commit },
  { type, id, path, notification }
) => {
  const labelSlug = path;
  const client = new DemoPlusMinusProxy(labelSlug, type);
  const data = {
    demo_id: id,
    action: "sign",
    label: labelSlug,
    skip_notification: !notification,
  };
  return new Promise((resolve, reject) => {
    client
      .update(0, data)
      .then(() => {
        commit(types.REMOVE_DEMO_FROM_LIST, {
          type,
          id,
        });
        resolve({
          type,
          id,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//
export const clickMinusOnDemo = (
  { commit },
  { type, id, notification, path }
) =>
  // TODO: create api request
  new Promise((resolve, reject) => {
    const client = new DemoPlusMinusProxy(path, type);
    const data = {
      demo_id: id,
      action: "reject",
      label: path,
      skip_notification: !notification,
    };
    client
      .update(0, data)
      .then((response) => {
        commit(types.REMOVE_DEMO_FROM_LIST, {
          type,
          id,
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
export default {
  fetchAllTracks,
  clickMinusOnDemo,
  clickPlusOnDemo,
};
