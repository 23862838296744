import { RESOURCE } from "../const";

export default {
  path: "/ar",
  name: "ar.index",
  component: () => import("@/views/A&R/Index.vue"),
  redirect: "/ar/toplines",
  // If the user needs to be authenticated to view this page.
  children: [
    {
      path: "toplines",
      name: RESOURCE.ar.toplines,
      component: () => import("@/views/A&R/page/Toplines.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.ar.toplines,
      },
    },
    {
      path: "projects",
      name: RESOURCE.ar.projects,
      component: () => import("@/views/A&R/page/Projects.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.ar.projects,
      },
    },
  ],
};
