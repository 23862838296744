<template>
  <div
    v-if="trackUrl"
    :class="{
      'pb-2': minimized,
      'pt-2': minimized,
    }"
    class="block mb-0 pos-fix w-100 p-2 pr-3 pl-3"
    style="bottom: 0; z-index: 2000; left: 0"
  >
    <div
      class="d-flex justify-content-between mb-0 mb-md-2 pt-1 pb-1 pt-md-0 pb-md-0"
    >
      <div class="align-self-center" @click="actionPlay">
        <button
          v-if="minimized"
          :class="{ 'plyr__control--pressed': play }"
          type="button"
          class="plyr__control"
        >
          <svg class="icon--pressed" role="presentation">
            <use xlink:href="#plyr-pause" />
          </svg>
          <svg class="icon--not-pressed" role="presentation">
            <use xlink:href="#plyr-play" />
          </svg>
          <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
          <span class="label--not-pressed plyr__tooltip" role="tooltip"
            >Play</span
          >
        </button>
      </div>
      <div v-if="minimized" class="pl-3 pr-3 plyr__title align-self-center">
        {{ name }}
      </div>
      <div class="ml-auto">
        <button
          v-if="minimized"
          class="btn btn-sm btn-light"
          @click="minimized = false"
        >
          Open player
        </button>
        <button v-else class="btn btn-sm btn-white" @click="minimized = true">
          Minimize player
        </button>
      </div>
    </div>
    <iframe
      :id="id"
      :class="{
        'player-minimized': minimized,
      }"
      allow="autoplay"
      class="no-border player-max-height"
      style="width: 100%"
    />
  </div>
</template>

<script>
import SoundcloudWidget from "@/plugins/vendor/soundcloud";

export default {
  name: "SoundCloudPlayer",
  data() {
    return {
      id: "soundCloudPlayer",
      widget: null,
      minimized: false,
      play: false,
      trackUrlData: {
        urlBase: "",
        params: {},
        url: "",
      },
    };
  },
  computed: {
    trackUrl() {
      return this.$store.state.player.trackUrl;
    },
    storePlay() {
      return this.$store.state.player.play;
    },
    name() {
      return this.$store.state.player.name;
    },
  },
  watch: {
    trackUrl(newTrack) {
      const trackUrlData = this.urlAndOptions(newTrack);
      const { url } = trackUrlData.params;
      const secretToken = encodeURI(
        `secret_token=${trackUrlData.params.secret_token}`
      );
      const params = { ...trackUrlData.params };
      delete params.url;
      delete params.secret_token;
      this.widget.load(`${url}?${secretToken}`, params);
    },
    play(newValue) {
      if (this.storePlay !== newValue) {
        this.$store.dispatch("player/run", newValue);
      }
    },
    storePlay(newValue) {
      if (!this.play && newValue) {
        this.widget.play();
      } else if (this.play && !newValue) {
        this.widget.pause();
      }
    },
  },
  created() {
    this.trackUrlData = this.urlAndOptions(this.trackUrl);
  },
  mounted() {
    const { trackUrlData } = this;
    const iframe = this.$el.querySelector(`#${this.id}`);
    iframe.src = trackUrlData.url;
    this.widget = SoundcloudWidget(iframe);
    const { Events } = SoundcloudWidget;

    this.widget.bind(Events.PLAY, () => {
      this.play = true;
    });

    this.widget.bind(Events.PAUSE, () => {
      this.play = false;
    });

    this.widget.bind(Events.FINISH, () => {
      this.play = false;
    });
  },
  methods: {
    urlAndOptions(url) {
      const urlBase = url.split("?")[0];
      const params = url.split("?")[1];
      const searchParams = new URLSearchParams(params);
      searchParams.append("auto_play", "true");
      return {
        urlBase,
        params: Object.fromEntries(searchParams),
        url: `${urlBase}?${searchParams.toString()}`,
      };
    },
    actionPlay() {
      if (this.play) {
        return this.widget.pause();
      }
      return this.widget.play();
    },
  },
};
</script>

<style scoped>
.player-max-height {
  max-height: 130px;
}
@media screen and (max-width: 768px) {
  .player-max-height {
    max-height: 100%;
    min-height: 60vh;
    height: 100%;
  }
}

.plyr__title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.player-minimized {
  max-height: 0 !important;
  height: 0 !important;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
</style>
