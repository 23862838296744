<template>
  <div
    class="item-tag text-muted tag flex"
    style="width: 0; height: 0; opacity: 0"
  >
    <img
      :src="generatePath(asset, isDemo)"
      class="imageWave img-responsive"
      alt="ll"
      style="width: 0; height: 0"
      @error="
        () => {
          errorImages(asset);
        }
      "
    />
  </div>
</template>

<script>
import WaveProxy from "../../../proxies/WaveProxy";
import WaveGeneratorMixin from "../../mixins/WaveGeneratorMixin";

export default {
  name: "WaveGeneratorIndex",
  mixins: [WaveGeneratorMixin],
  props: {
    isDemo: {
      type: Boolean,
      default: false,
    },
    asset: {
      type: Object,
      required: true,
    },
  },
  methods: {
    errorImages(assets) {
      if (!assets.waveform_prefix) {
        console.warn("field 'waveform_prefix'  not set in bettyblocks API");
        return;
      }
      const waveForm = new WaveProxy({}, this.isDemo);
      waveForm
        .create(assets)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped></style>
