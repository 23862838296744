import {
  FINANCE_STATS_MONTH,
  FINANCE_RIGHTHOLDER_TRACK,
  RESOURCE,
  FINANCE_RIGHTHOLDER_DASHBOARD,
  FINANCE_RIGHTHOLDER_SHOPS,
  FINANCE_SHOPS,
} from "@/routes/const";
import setLabelBeforeEnter from "@/routes/setLabelBeforeEnter";

export default {
  path: "/finance",
  name: "finance.index",
  redirect: "/finance/dashboard",
  component: () => import("@/views/Finance/Index.vue"),
  children: [
    {
      path: "dashboard",
      component: () => import("@/views/Finance/Dashboard/Index.vue"),
      name: "finance.dashboard",
    },
    {
      path: "stats/month/:date",
      component: () => import("@/views/Finance/Dashboard/MonthStats.vue"),
      name: FINANCE_STATS_MONTH,
    },
    {
      path: "countries",
      component: () => import("@/views/Finance/Countries/Index.vue"),
      name: "finance.countries",
    },
    {
      path: "countries/:countryName/:parentLabelPath",
      component: () =>
        import("@/views/Finance/Countries/CountriesSinglePage.vue"),
      name: "finance.countries-single",
      beforeEnter: setLabelBeforeEnter,
    },
    {
      path: "tracks",
      component: () => import("@/views/Finance/Tracks/Index.vue"),
      name: "finance.tracks",
    },
    {
      path: "shops",
      component: () => import("@/views/Finance/Shops/Index.vue"),
      name: FINANCE_SHOPS,
    },
    {
      path: "shops/:shopName/:parentLabelPath",
      component: () => import("@/views/Finance/Shops/ShopSinglePage.vue"),
      name: "finance.shops-single",
      beforeEnter: setLabelBeforeEnter,
    },
    {
      path: "rightholder",
      component: () => import("@/views/Finance/Rightholder/Index.vue"),
      name: "finance.rightholder",
    },
    {
      path: "rightholder-statement",
      component: () =>
        import("@/views/Finance/Rightholder/Statements/Index.vue"),
      name: "finance.rightholder.statement",
      meta: {
        auth: true,
        resource: RESOURCE.finance.rightholderStatementManager,
      },
    },
    {
      path: "transactions",
      component: () => import("@/views/Finance/Transactions/Index.vue"),
      name: "finance.transactions",
    },
    {
      path: "rightholder/:id",
      component: () => import("@/views/Finance/Admin/Index.vue"),
      name: "finance.rightholder.index",
      children: [
        {
          path: "dashboard",
          component: () =>
            import("@/views/Finance/Admin/RightholderAdminDashboard.vue"),
          name: FINANCE_RIGHTHOLDER_DASHBOARD,
          meta: {
            auth: true,
            resource: RESOURCE.finance.admin,
          },
        },
        {
          path: "tracks",
          component: () => import("@/views/Finance/Tracks/Index.vue"),
          name: "finance.rightholder.tracks",
          meta: {
            auth: true,
            resource: RESOURCE.finance.admin,
          },
        },
        {
          path: "countries",
          component: () => import("@/views/Finance/Countries/Index.vue"),
          name: "finance.rightholder.countries",
          meta: {
            auth: true,
            resource: RESOURCE.finance.admin,
          },
        },
        {
          path: "countries/:countryName/:labelName",
          component: () =>
            import("@/views/Finance/Countries/CountriesSinglePage.vue"),
          name: RESOURCE.finance.countriesSingle,
        },
        {
          path: "shops/:shopName/:labelName",
          component: () => import("@/views/Finance/Shops/ShopSinglePage.vue"),
          name: RESOURCE.finance.shopsSingle,
        },
        {
          path: "shops",
          component: () => import("@/views/Finance/Shops/Index.vue"),
          name: FINANCE_RIGHTHOLDER_SHOPS,
          meta: {
            auth: true,
            resource: RESOURCE.finance.admin,
          },
        },
        {
          path: "transactions",
          component: () => import("@/views/Finance/Transactions/Index.vue"),
          name: "finance.rightholder.transactions",
          meta: {
            auth: true,
            resource: RESOURCE.finance.admin,
          },
        },
        {
          path: "statements",
          component: () => import("@/views/Finance/Statements/Index.vue"),
          name: "finance.rightholder.statements",
          meta: {
            auth: true,
            resource: RESOURCE.finance.admin,
          },
        },
        {
          path: "track/:trackId",
          component: () => import("@/views/Finance/Tracks/TrackSinglePage.vue"),
          name: FINANCE_RIGHTHOLDER_TRACK,
          meta: {
            auth: true,
            action: "auth",
            resource: RESOURCE.finance.admin,
          },
        },
      ],
    },
    {
      path: "track/:trackId",
      component: () => import("@/views/Finance/Tracks/TrackSinglePage.vue"),
      name: "finance.track",
      meta: {
        auth: true,
        action: "auth",
        resource: RESOURCE.loggedIn,
      },
    },
    {
      path: "reload",
      component: () => import("@/views/Finance/ReloadData/Index.vue"),
      name: RESOURCE.finance.reloadData,
      action: "run",
      meta: {
        auth: true,
        resource: RESOURCE.finance.reloadData,
      },
    },
  ],
  // If the user needs to be authenticated to view this page.
  meta: {
    auth: true,
    resource: RESOURCE.finance.main,
  },
};
