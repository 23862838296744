export const HOME = "home";
export const FINANCE_DASHBOARD = "finance.dashboard";
export const FINANCE_SHOPS = "finance.shops";
export const FINANCE_SHOP_SINGLE = "finance.shops-single";
export const FINANCE_STATS_MONTH = "finance.stats.month";
export const FINANCE_RIGHTHOLDER_TRACK = "finance.rightholder.track";
export const FINANCE_RIGHTHOLDER_DASHBOARD = "finance.rightholder.dashboard";
export const FINANCE_RIGHTHOLDER_SHOPS = "finance.rightholder.shops";
export const FINANCE_RIGHTHOLDER_SHOP_SINGLE =
  "finance.rightholder.shops-single";
export const ADMIN_MAIN_PAGE = "creative.dashboard";
export const BACKOFFICE_MAIN_PAGE = "backoffice.releaseschedule";
export const DEMOBOX_MAIN_PAGE = "creative.demobox.primary";
export const CREATIVE_MAIN_PAGE = DEMOBOX_MAIN_PAGE;
export const LICENSING_MAIN_PAGE = "creative.licensing.primary";
export const LICENSING_ADMIN_DASHBOARD_PAGE = "labels.licensing-dashboard";
export const LICENSING_UNASSIGNED_PAGE = "creative.licensing.unassigned";
export const LICENSING_DASHBOARD_PAGE = "creative.licensing.dashboard";
export const LICENSING_PRIMARY_PAGE = "creative.licensing.primary";
export const LICENSING_SECONDARY_PAGE = "creative.licensing.secondary";
export const LICENSING_APPROVED_PAGE = "creative.licensing.approved";
export const LICENSING_MANAGER_MAIN_PAGE = "creative.licensing.unassigned";
export const BACKOFFICE_RELEASE_SINGLE_PAGE = "backoffice.release_page";
export const BACKOFFICE_ISSUE_SINGLE_PAGE = "backoffice.issue.singlePage";
export const BACKOFFICE_PROJECT_BOARD_PAGE = "backoffice.projectBoard";
export const DEMO_LICENSING_SINGLE_PAGE = "creative.licensing.single";
export const DATA_ENTRY_MAIN = "dataEntry.streaming";
export const SALES_DEALS_RECORDS = "sales.deals.records";
export const BRIEFING_SYNC = "briefing.sync";
export const BRIEFING_CREATIVE = "briefing.creative";
export const BRIEFING_SINGLE_PAGE = "briefing.single.page";
export const SALES_DEALS_EXPLOITATION = "sales.deals.exploitation";
export const SALES_DEALS_LICENSING = "sales.deals.licensing";
export const SALES_DEALS_PUBLISHING = "sales.deals.publishing";
export const SALES_DEALS_BRIEFING = "sales.deals.briefing";
export const SALES_READY_FOR_CONTACT = "sales.contact.ready-for";
export const SALES_IN_CONTACT = "sales.contact.in";
export const SALES_CONTACT_SINGLE = "sales.contact.single";
export const SALES_TASKS = "sales.tasks";
export const GENERAL_TASKS = "general";
export const GENERAL_PROJECT_BOARD = "project.board";
export const AR_PROJECTS = "ar.projects";
export const AR_TOPLINES = "ar.toplines";
export const SALES_DEAL_SINGLE_PAGE = "sales.deals.single";
export const CREATIVE_PROJECT_SINGLE_PAGE = "creative.project.single";
export const CREATIVE_TOPLINE_SINGLE_PAGE = "creative.topline.single";
export const CREATIVE_INSTRUMENTAL_SINGLE_PAGE = "creative.instrumental.single";
export const CREATIVE_PROJECT_CONVERT_PAGE = "creative.project.convert";
export const CREATIVE_PROJECT_PAGE = "creative.project";

export const CREATIVE_TOPLINES_CONCEPT = "creative.toplines.concept";
export const CREATIVE_INSTRUMENTAL_CONCEPT = "creative.instrumental.concept";

export const CREATIVE_INSTRUMENTAL_INBOX = "creative.instrumental.inbox";
export const CREATIVE_INSTRUMENTAL_REQUESTED =
  "creative.instrumental.requested";
export const CREATIVE_INSTRUMENTAL_APPROVED = "creative.instrumental.approved";
export const CREATIVE_INSTRUMENTAL_REJECT = "creative.instrumental.reject";

export const CREATIVE_TOPLINES_INBOX = "creative.toplines.inbox";
export const CREATIVE_TOPLINES_REQUESTED = "creative.toplines.requested";
export const CREATIVE_TOPLINES_APPROVED = "creative.toplines.approved";

export const BACKOFFICE_RELEASE_SCHEDULED = "backoffice.releaseschedule";
export const BACKOFFICE_CATALOG_RELEASES = "backoffice.catalog.releases";

export const DEAL_TYPE = {
  Records: "Records",
  Exploitation: "Exploitation",
  Publishing: "Publishing",
  Licensing: "Licensing",
  Briefing: "Briefing",
};

export const RESOURCE = {
  loggedIn: "userLoggedIn",
  creative: {
    main: "creative",
    nested: {
      dashboard: "creative.dashboard",
      primary: "creative.demobox.primary",
      secondary: "creative.demobox.secondary",
      projects: CREATIVE_PROJECT_PAGE,
      projectSchedule: "creative.projects-schedule",
      toplinesConcept: CREATIVE_TOPLINES_CONCEPT,
      creativeInboxModule: "creative.inboxModule",

      instrumentalsConcept: CREATIVE_INSTRUMENTAL_CONCEPT,
      instrumentalsRequested: CREATIVE_INSTRUMENTAL_REQUESTED,
      instrumentalsApproved: CREATIVE_INSTRUMENTAL_APPROVED,
      instrumentalsInbox: CREATIVE_INSTRUMENTAL_INBOX,

      toplinesRequested: CREATIVE_TOPLINES_REQUESTED,
      toplinesApproved: CREATIVE_TOPLINES_APPROVED,
      toplinesInbox: CREATIVE_TOPLINES_INBOX,
      licensingDashboard: "creative.licensing.dashboard",
      unassigned: "creative.licensing.unassigned",
      licensingPrimary: "creative.licensing.primary",
      licensingSecondary: "creative.licensing.secondary",
    },
  },
  backoffice: {
    main: "backoffice",
    nested: {
      catalog: BACKOFFICE_CATALOG_RELEASES,
      artists: "backoffice.catalog.artists",
      metadata: "backoffice.metadata",
      release_schedule: BACKOFFICE_RELEASE_SCHEDULED,
      release_page: "backoffice.release_page",
      audio_assets: "backoffice.audioassets",
      project_pages: BACKOFFICE_PROJECT_BOARD_PAGE,
    },
  },
  account: { main: "account" },
  performance: { main: "performance" },
  newperformance: { main: "newperformance" },
  finance: {
    main: "finance",
    admin: FINANCE_RIGHTHOLDER_DASHBOARD,
    rightholderStatementManager: "finance.rightholder.statement.manager",
    transactions: "finance.rightholder.transactions",
    statements: "finance.rightholder.statements",
    countries: "finance.rightholder.countries",
    countriesSingle: "finance.rightholder.countries-single",
    tracks: "finance.rightholder.tracks",
    singleTrack: FINANCE_RIGHTHOLDER_TRACK,
    shops: FINANCE_RIGHTHOLDER_SHOPS,
    shopsSingle: FINANCE_RIGHTHOLDER_SHOP_SINGLE,
    downloadExcel: "download.excel",
    reloadData: "financeUser.reloadData",
  },
  dataEntry: {
    main: "dataEntry.streaming",
    test: "dataEntry.test",
  },
  sales: {
    deals: SALES_DEALS_RECORDS,
    deal: SALES_DEAL_SINGLE_PAGE,
    tasks: SALES_TASKS,
    contacts: SALES_READY_FOR_CONTACT,
  },
  ar: {
    projects: AR_PROJECTS,
    toplines: AR_TOPLINES,
  },
  generalTasks: GENERAL_TASKS,
  projectBoard: GENERAL_PROJECT_BOARD,
  briefing: {
    main: BRIEFING_CREATIVE,
    sync: BRIEFING_SYNC,
  },
  labels: {
    all: "labels.all",
    create: "labels.create",
    edit: "labels.edit",
    view: "labels.view",
  },
  publicPages: "publicPages",
};
