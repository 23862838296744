import RightholderStatementsByLabelProxy from "@/proxies/finance/rightholder/statement/RightholderStatementsByLabelProxy";
import types from "./mutation-types";

export const getTotalGeneratingStatus = ({ commit, rootState }) => {
  const { id, identifier, currentLabel } = rootState.account;

  return new RightholderStatementsByLabelProxy(currentLabel, id, identifier)
    .getTotalGeneratingStatementStatus()
    .then((response) => {
      commit(types.SET_IS_TOTAL_GENERATING_IN_PROCESS, response.isRunning);
    });
};
export default {
  getTotalGeneratingStatus,
};
