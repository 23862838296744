/* ============
 * State of the demobox module
 * ============
 *
 * The initial state of the demobox module.
 */

export default {
  primaryDemoList: [],
  secondaryDemoList: [],
  approvedDemosLoading: false,
  approvedDemos: [],
  signedDemosLoading: false,
  signedDemos: [],
  primaryRejected: {
    count: 0,
  },
  secondaryRejected: {
    count: 0,
  },
};
