/* ============
 * Mutations for the demobox module
 * ============
 *
 * The mutations that are available on the
 * demobox module.
 */

import * as types from "./mutation-types";
import {
  DEMO_TYPE_PRIMARY,
  DEMO_TYPE_SECONDARY,
} from "../../../../const/labels";

/* eslint-disable no-param-reassign */
export default {
  [types.SET_PRIMARY_DEMO_LIST](state, demoList) {
    state.primaryDemoList = [...state.primaryDemoList, ...demoList];
  },
  [types.SET_SECONDARY_DEMO_LIST](state, demoList) {
    state.secondaryDemoList = [...state.secondaryDemoList, ...demoList];
  },
  [types.SET_PRIMARY_REJECT_COUNT](state, count) {
    if (count) {
      state.primaryRejected = { count };
    }
  },
  [types.SET_SECONDARY_REJECT_COUNT](state, count) {
    if (count) {
      state.secondaryRejected = { count };
    }
  },

  [types.SET_PRIMARY_REJECT_DATA](state, rejectedArray) {
    if (rejectedArray) {
      state.primaryDemoList = [...state.primaryDemoList, ...rejectedArray];
    }
  },
  [types.SET_SECONDARY_REJECT_DATA](state, rejectedArray) {
    if (rejectedArray) {
      state.secondaryDemoList = [...state.secondaryDemoList, ...rejectedArray];
    }
  },
  [types.CLEAR_PRIMARY_DATA](state) {
    state.primaryDemoList = [];
    state.approvedDemos = [];
  },
  [types.CLEAR_SECONDARY_DATA](state) {
    state.secondaryDemoList = [];
  },
  [types.REMOVE_DEMO_FROM_LIST](state, { type, id }) {
    switch (type) {
      case DEMO_TYPE_PRIMARY:
        state.primaryDemoList = state.primaryDemoList.filter(
          (demo) => demo.id !== id
        );
        break;
      case DEMO_TYPE_SECONDARY:
        state.secondaryDemoList = state.secondaryDemoList.filter(
          (demo) => demo.id !== id
        );
        break;
      default:
        break;
    }
  },
  [types.SET_STARRED_DEMO](state, { type, status, identifier }) {
    const setStared = (demo) => {
      if (demo.identifier === identifier) {
        return { ...demo, starred: status };
      }
      return demo;
    };
    switch (type) {
      case DEMO_TYPE_PRIMARY:
        state.primaryDemoList = state.primaryDemoList.map(setStared);
        break;
      case DEMO_TYPE_SECONDARY:
        state.secondaryDemoList = state.secondaryDemoList.map(setStared);
        break;
      default:
        break;
    }
  },
  [types.SET_STATUS](state, { type, status, id }) {
    const setStatus = (demo) => {
      if (demo.id === id) {
        return { ...demo, status };
      }
      return demo;
    };
    switch (type) {
      case DEMO_TYPE_PRIMARY:
        state.primaryDemoList = state.primaryDemoList.map(setStatus);
        break;
      case DEMO_TYPE_SECONDARY:
        state.secondaryDemoList = state.secondaryDemoList.map(setStatus);
        break;
      default:
        break;
    }
  },
  [types.SET_PRIMARY_APPROVED_DATA](state, approved) {
    state.approvedDemos = [...approved];
  },
  [types.SET_SIGNED_DATA](state, signed) {
    state.signedDemos = signed;
  },
  [types.SET_APPROVED_LOADING](state, status) {
    state.approvedDemosLoading = !!status;
  },
  [types.SET_SIGNED_LOADING](state, status) {
    state.signedDemosLoading = !!status;
  },
};
