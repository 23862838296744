/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const FIND = "FIND";
export const SET_CURRENT_LABEL = "SET_CURRENT_LABEL";
export const LOADING_ACCOUNT_DATA = "LOADING_ACCOUNT_DATA";
export const LOADING_RELEASE_MANAGERS = "LOADING_RELEASE_MANAGERS";
export const RESET = "RESET_ACCOUNT";
export const LOADING_ASSIGNED_USERS_IN_DEAL = "LOADING_ASSIGNED_USERS_IN_DEAL";

export default {
  FIND,
  SET_CURRENT_LABEL,
  LOADING_ACCOUNT_DATA,
  RESET,
  LOADING_RELEASE_MANAGERS,
  LOADING_ASSIGNED_USERS_IN_DEAL,
};
