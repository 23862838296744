import creativeAbilities from "./creative";
import demoboxAbilities from "./demobox";
import briefingAbilities from "./briefing";
import creativeMain from "./creativeMain";
import creativeToplineModule from "./creativeToplineModule";
import creativeInstrumentalModule from "./creativeInstrumentalModule";
import project from "./project";
import recordLabel from "./recordLabel";

/**
 *
 * @param {object} account
 * @param {array} account.label_managers
 * @param {array} account.creative
 * @param {array} account.demobox
 * @param {array} account.labels_manager_licensing
 * @param {array} account.licensing
 * @param {number} currentLabel
 * @returns {array | []}
 */
export default (account, currentLabel) => {
  const {
    permissions: {
      label_managers: labelManagers,
      creative,
      demobox,
      creative_project: creativeProject,
    },
  } = account;

  const curLabelData =
    account.labels && account.labels.find((label) => label.id === currentLabel);

  const { modules } = curLabelData || { modules: {} };
  let rules = [];

  rules = rules.concat(recordLabel(account, curLabelData));

  if (labelManagers.includes(currentLabel)) {
    rules = rules.concat(creativeAbilities()).concat(project());
  }

  if (creative.includes(currentLabel)) {
    rules = rules
      .concat(demoboxAbilities())
      .concat(project())
      .concat(briefingAbilities())
      .concat(creativeMain());
  }

  if (demobox.includes(currentLabel)) {
    rules = rules.concat(demoboxAbilities()).concat(creativeMain());
  }

  if (creativeProject.includes(currentLabel)) {
    rules = rules.concat(project()).concat(creativeMain());
  }

  if (modules && !modules.demobox) {
    const demoboxSubjects = demoboxAbilities().map((item) => item.subject);
    rules = rules.filter((rule) => !demoboxSubjects.includes(rule.subject));
  }

  /* Module Creative */
  if (labelManagers.includes(currentLabel) || creative.includes(currentLabel)) {
    if (modules && modules.creative_inbox_type === "Topline") {
      rules = rules.concat(creativeToplineModule());
    } else if (modules && modules.creative_inbox_type === "Instrumental") {
      rules = rules.concat(creativeInstrumentalModule());
    }
    rules = rules.concat(creativeMain());
  }

  return rules;
};
