import Vue from "vue";

class CsrfProxy {
  /**
   * Method used to get valid CSRF.
   *
   *
   * @returns {Promise} The result in a promise.
   */
  getCSRF() {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get("app/data/get/csrf")
        .then((response) => {
          const html = response.data;
          // eslint-disable-next-line no-undef
          const csrf = $(html).val();
          if (csrf) {
            resolve(csrf);
          } else {
            reject(new Error("can't get csrf"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default CsrfProxy;
