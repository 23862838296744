import { API_PARAM_LABEL_ID } from "@/const/labels";
import Proxy from "../../../Proxy";
import NoCredentialMixin from "../../../../mixins/proxyNoCredensials";
import PATH from "../../../../const/PATH";

/**
 * @param labelId int
 * @param userId - id from Account User
 * @param userIdentifier - identifier from Account User
 */
class RightholderStatementsByLabelProxy extends Proxy {
  constructor(labelId, userId, userIdentifier) {
    super(
      `${PATH.SERVICE_SERVER}api/financial/labels/${labelId}/statements`,
      {},
      {
        headers: {
          Authorization: `${userId}:${userIdentifier}`,
        },
        params: {
          [API_PARAM_LABEL_ID]: labelId,
        },
      }
    );
  }

  generateAllStatement() {
    return this.submit("post", `${this.endpoint}/generate`);
  }

  getTotalGeneratingStatementStatus() {
    return this.submit("get", `${this.endpoint}/generate/status`);
  }
}

Object.assign(RightholderStatementsByLabelProxy.prototype, NoCredentialMixin);
export default RightholderStatementsByLabelProxy;
