import {
  DEAL_TYPE,
  RESOURCE,
  SALES_READY_FOR_CONTACT,
  SALES_DEAL_SINGLE_PAGE,
  SALES_DEALS_BRIEFING,
  SALES_DEALS_EXPLOITATION,
  SALES_DEALS_LICENSING,
  SALES_DEALS_PUBLISHING,
  SALES_DEALS_RECORDS,
  SALES_TASKS,
  SALES_CONTACT_SINGLE,
  SALES_IN_CONTACT,
} from "../const";

export default {
  path: "/sales",
  name: "sales.index",
  component: () => import("@/views/SalesModule/Index.vue"),
  redirect: "/sales/business",
  // If the user needs to be authenticated to view this page.
  children: [
    {
      path: "business",
      name: SALES_DEALS_RECORDS,
      component: () => import("@/views/SalesModule/pages/DealsPage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.deals,
        dealType: DEAL_TYPE.Records,
        pipelineGroup: "Records",
      },
    },
    {
      path: "exploitation",
      name: SALES_DEALS_EXPLOITATION,
      component: () => import("@/views/SalesModule/pages/DealsPage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.deals,
        dealType: DEAL_TYPE.Exploitation,
        pipelineGroup: "Exploitation",
      },
    },
    {
      path: "licensing",
      name: SALES_DEALS_LICENSING,
      component: () => import("@/views/SalesModule/pages/DealsPage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.deals,
        dealType: DEAL_TYPE.Licensing,
        pipelineGroup: "Records",
      },
    },
    {
      path: "publishing",
      name: SALES_DEALS_PUBLISHING,
      component: () => import("@/views/SalesModule/pages/DealsPage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.deals,
        dealType: DEAL_TYPE.Publishing,
        pipelineGroup: "Publishing",
      },
    },
    {
      path: "briefing",
      name: SALES_DEALS_BRIEFING,
      component: () => import("@/views/SalesModule/pages/DealsPage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.deals,
        dealType: DEAL_TYPE.Briefing,
        sortPipeline: true,
        pipelineGroup: "Briefing",
      },
    },

    {
      path: "contact",
      name: SALES_READY_FOR_CONTACT,
      component: () => import("@/views/SalesModule/Contacts/Index.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.contacts,
      },
      redirect: "/contact/ready-for",
      children: [
        {
          path: "ready-for",
          name: SALES_READY_FOR_CONTACT,
          component: () =>
            import(
              "@/views/SalesModule/Contacts/Pages/ReadyForContactPage.vue"
            ),
          meta: {
            auth: true,
            resource: RESOURCE.sales.contacts,
          },
        },
        {
          path: "in",
          name: SALES_IN_CONTACT,
          component: () =>
            import("@/views/SalesModule/Contacts/Pages/InContactPage.vue"),
          meta: {
            auth: true,
            resource: RESOURCE.sales.contacts,
          },
        },
        {
          path: ":id",
          name: SALES_CONTACT_SINGLE,
          component: () =>
            import(
              "@/views/SalesModule/Contacts/Pages/ReadyForContactPageSingle.vue"
            ),
          meta: {
            auth: true,
            resource: RESOURCE.sales.contacts,
          },
        },
      ],
    },
    {
      path: "deal/:id",
      name: SALES_DEAL_SINGLE_PAGE,
      component: () => import("@/views/SalesModule/pages/DealSinglePage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.deal,
      },
    },
    {
      path: "tasks",
      name: SALES_TASKS,
      component: () => import("@/views/SalesModule/pages/TasksSales.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.sales.tasks,
      },
    },
  ],
};
