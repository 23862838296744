import AuthProxy from "../../../proxies/AuthProxy";
import * as types from "./mutation-types";

export const check = ({ commit }) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (accessToken) {
    commit(types.SET_AUTHENTICATION, {
      isAuthenticated: true,
      accessToken,
      refreshToken,
    });
  } else {
    commit(types.SET_AUTHENTICATION, { isAuthenticated: false });
  }
};

export const register = async ({ commit }) => {
  try {
    const response = await new AuthProxy().register();
    const { access_token, refresh_token } = response.token;
    commit(types.SET_AUTHENTICATION, {
      isAuthenticated: true,
      accessToken: access_token,
      refreshToken: refresh_token,
    });
  } catch (error) {
    console.error(error);
  }
};

export const refreshToken = async ({ commit, dispatch }) => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      dispatch("logout");
      return;
    }
    const response = await new AuthProxy().refresh(refreshToken);
    const { access_token, refresh_token, expires_in } = response.data;

    commit(types.SET_AUTHENTICATION, {
      isAuthenticated: true,
      accessToken: access_token,
      refreshToken: refresh_token,
    });

    setTimeout(() => {
      dispatch("refreshToken");
    }, expires_in * 1000);
  } catch (error) {
    console.error("Failed to refresh token", error);
    dispatch("logout");
  }
};

export const login = async ({ commit, dispatch }, credentials) => {
  try {
    const response = await new AuthProxy().login(credentials);
    const { access_token, refresh_token, expires_in } = response;

    commit(types.SET_AUTHENTICATION, {
      isAuthenticated: true,
      accessToken: access_token,
      refreshToken: refresh_token,
    });

    setTimeout(() => {
      dispatch("refreshToken");
    }, expires_in * 1000);

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const logout = ({ commit }) => {
  commit(types.SET_AUTHENTICATION, { isAuthenticated: false });
};

export default {
  check,
  register,
  login,
  logout,
  refreshToken,
};
