<template>
  <div
    :style="{
      position: 'relative',
      cursor: isEditable ? 'pointer' : 'default',
    }"
    class="text-left relative track-field"
    @click="onClick"
  >
    <div class="d-flex flex-row align-items-center">
      <div v-if="release" :style="playButtonStyle">
        <track-with-play :item="release" />
      </div>
      <div class="d-flex flex-column" @click="$emit('click')">
        <span class="h-1x text-white">
          {{ title }}
        </span>
        <span
          v-if="artistBilling"
          class="h-1x small artist-billing d-flex align-items-end"
          style="line-height: 22px"
        >
          {{ artistBilling }}
        </span>
        <span
          v-if="atrtistName"
          class="h-1x small artist-billing d-flex align-items-end"
        >
          {{ atrtistName }}
        </span>
      </div>
      <div>
        <b-badge
          v-if="badge.title.length !== 0"
          :variant="badge.variant"
          :class="badge.class"
          class="ml-2 font-size-75"
        >
          {{ badge.title }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import TrackWithPlay from "./TrackWithPlay.vue";

export default {
  name: "TrackField",
  components: {
    TrackWithPlay,
    BBadge,
  },
  props: {
    playButtonStyle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    artistBilling: {
      type: String,
      default: "",
    },
    atrtistName: {
      type: String,
      default: "",
    },
    artist: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Object,
      default: () => ({
        title: "",
        variant: "primary",
        class: "",
      }),
    },
    release: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClick() {
      if (this.isEditable) {
        this.$emit("edit");
      }
    },
  },
};
</script>

<style>
.h-1x {
  height: 1.2rem !important;
}
</style>
