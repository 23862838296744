import { RESOURCE } from "../../../../routes/const";
import ROLES from "../../../../const/Roles";

export default (account) => {
  const { roles } = account;
  const rules = [];
  if (
    Array.isArray(roles) &&
    roles.map((role) => role.toLowerCase()).includes(ROLES.AR.toLowerCase())
  ) {
    rules.push({
      action: "manage",
      subject: RESOURCE.ar.projects,
    });

    rules.push({
      action: "manage",
      subject: RESOURCE.ar.toplines,
    });
  }
  return rules;
};
