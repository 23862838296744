import DealProxy from "../../proxies/sales/DealProxy";
import {
  FIND,
  LOADING_ASSIGNED_USERS_IN_DEAL,
  SET_CURRENT_LABEL,
} from "../modules/account/mutation-types";
import { RESOURCE } from "../../routes/const";
import { ability } from "../../plugins/abilities";

export default (store) => {
  store.subscribe(async (mutation, { account }) => {
    const { type } = mutation;
    const { currentLabel } = account;

    const relevantMutations = [
      `account/${SET_CURRENT_LABEL}`,
      `account/${FIND}`,
    ];
    if (!relevantMutations.includes(type) || !currentLabel) return;

    const rights = [RESOURCE.sales.deals];
    const userCan = rights.some((permission) =>
      ability.can("manage", permission)
    );

    if (userCan) {
      try {
        const { data } = await new DealProxy().getUsersUssignetTo();
        store.commit(`account/${LOADING_ASSIGNED_USERS_IN_DEAL}`, data.users);
      } catch (e) {
        console.error(`Cannot load users assigned to. errors: ${e}`);
      }
    }
  });
};
