import { CREATIVE_PROJECT_ASSET_MARKED_MAIN } from "./mutation-types";
import ProjectProxy from "../../../proxies/creative/ProjectProxy";

const updateCreativeProjectMainAudio = (
  { commit },
  { projectIdentifier, assetIdentifier, asset }
) => {
  new ProjectProxy()
    .setMainAudio(projectIdentifier, assetIdentifier)
    .then(() => {
      commit(CREATIVE_PROJECT_ASSET_MARKED_MAIN, {
        projectIdentifier,
        assetIdentifier,
        asset,
      });
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .catch(() => {});
};

export default {
  updateCreativeProjectMainAudio,
};
