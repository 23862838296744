<template>
  <div class="card mb-4 position-relative">
    <h5>Daily Streams</h5>
    <line-chart :chart-data="dataCollection" :height="200" :options="options" />
    <Loader :is-loading="dataCollectionLoading" :is-fixed="false" />
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "../../../components/Charts/LineChart";
import LineChartDataProxy from "../../../../proxies/creative/licensing/LineChartDataProxy";
import Loader from "../../../components/Table/Loader.vue";

export default {
  name: "StreamingStatisticsModal",
  components: {
    LineChart,
    Loader,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataCollection: {},
      dataCollectionLoading: false,
      options: {
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                displayFormats: { day: "DD.MM" },
                tooltipFormat: "DD.MM",
                unit: "day",
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.LineChartData();
  },
  methods: {
    LineChartData() {
      this.dataCollectionLoading = true;
      const client = new LineChartDataProxy();
      client
        .getData(this.track.identifier)
        .then((response) => {
          this.parseResponseLineChart(response);
        })
        .catch((e) => {
          this.dataCollection = null;
          console.log(e);
        })
        .finally(() => {
          this.dataCollectionLoading = false;
        });
    },
    getAbsoluteMonths(momentDate) {
      const months = Number(momentDate.format("MM"));
      const years = Number(momentDate.format("YYYY"));
      return months + years * 12;
    },
    parseResponseLineChart(response) {
      const responseArr = [];
      Object.entries(response).forEach(([date, value]) => {
        const dateVal = moment(date);
        responseArr.push({
          date: dateVal,
          income: value,
        });
      });
      responseArr.sort((a, b) => a.date.diff(b.date));

      if (!response || response === []) {
        return;
      }
      const dataIncome = [];

      const labels = [];

      responseArr.forEach(({ date, income }) => {
        dataIncome.push(income);
        labels.push(date);
      });

      const datasets = [];
      const actual = {
        label: "Streams",
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "#007bff",
        borderCapStyle: "butt",
        pointBorderColor: "#007bff",
        pointBorderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: "#007bff",
        data: dataIncome,
      };
      datasets.push(actual);
      this.dataCollection = {
        labels,
        datasets,
      };
    },
  },
};
</script>
<style scoped>
.card {
  background-color: #212121;
  border-width: 0;
  padding: 1.5rem !important;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05),
    0 -1px 0px rgba(0, 0, 0, 0.015);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 -1px 0px rgba(0, 0, 0, 0.015);
  min-height: 450px;
}
@media screen and (max-width: 991px) {
  .card {
    min-height: 150px;
  }
}
</style>
