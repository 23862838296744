import Proxy from "./Proxy";

class AuthProxy extends Proxy {
  /**
   * The constructor for the ArtistProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("", parameters);
  }

  /**
   * Method used to login.
   *
   * @param {String} login The username.
   * @param {String} password The password.
   *
   * @returns {Promise} The result in a promise.
   */
  login({ login, password }) {
    const data = {
      email: login,
      password,
    };

    return this.submit("post", `${this.endpoint}/clients/web/login`, data);
  }

  /**
   * Method used to refresh.
   *
   * @param {String} refreshToken  Refresh Token.
   *
   * @returns {Promise} The result in a promise.
   */
  refresh(refreshToken) {
    const data = {
      refresh_token: refreshToken,
    };

    return this.submit("post", `${this.endpoint}/clients/web/refresh`, data);
  }
}

export default AuthProxy;
