import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = [];

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.creativeInboxModule,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.instrumentalsConcept,
  });

  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.toplinesRequested,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.toplinesApproved,
  });
  rules.push({
    action: "manage",
    subject: RESOURCE.creative.nested.toplinesInbox,
  });

  return rules;
};
