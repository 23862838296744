/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */
import "./plugins/jquery";

import Vue from "vue";

/* ============
 * Plugins
 * ============
 *
 * Import and bootstrap the plugins.
 */

import "bootstrap/js/dist/tab";
import "./plugins/vuex";
import "./plugins/vue-notie";
import "./plugins/global-components";
import "./plugins/vue-clipboard";
import "./filters/textHelpers";
import "./plugins/axios";
import "./plugins/vue-modal";
import { i18n } from "./plugins/vue-i18n";
import { router } from "./plugins/vue-router";
import "./plugins/vuex-router-sync";
import "./assets/js/fix-bug-dropdown-bootstrap";
import "./plugins/font-awesome";
import "./plugins/abilitiesPlugin";

/* ============
 * Styling
 * ============
 */
import "./assets/scss/_notie.scss";
import "./assets/scss/bootstrap.scss";
import "./assets/scss/i-con.scss";
import "./assets/scss/theme.scss";
import "./assets/js/libs/plyrist/src/plyrist.css";

/* ============
 * Main App
 * ============
 *
 * Last but not least, we import the main application.
 */
/*
 * Abilities
 */

import App from "./App.vue";
import store from "./store";
import { appVars } from "@/const/AppVars";


Vue.prototype.$isProduction = appVars.isProduction;
Vue.config.performance = true;
Vue.config.productionTip = false;
store.dispatch("auth/check");

/* eslint-disable no-new */
const appObject = new Vue({
  /**
   * Bind the Vue instance to the HTML.
   */
  el: "#app",

  /**
   * The localization plugin.
   */
  i18n,

  /**
   * The router.
   */
  router,

  /**
   * The Vuex store.
   */
  store,

  /**
   * Will render the application.
   *
   * @param {Function} h Will create an element.
   */
  render: (h) => h(App),
});

if (process.env.NODE_ENV !== "production") {
  window.appObject = appObject;
}

export default appObject;
