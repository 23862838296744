/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const CHECK = "CHECK";
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";

export default {
  CHECK,
  REGISTER,
  LOGIN,
  LOGOUT,
  SET_AUTHENTICATED,
  SET_REFRESH_TOKEN,
  SET_ACCESS_TOKEN,
  SET_AUTHENTICATION,
};
