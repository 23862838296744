<template>
  <div class="licensing__modal_card p-3 mb-4 card">
    <h5 class="pb-4">Statistic</h5>
    <div class="text-muted text-sm">
      <h6>Total streams</h6>
    </div>
    <div class="pb-2">
      {{ track.streams_total | formattedNumber }}
    </div>
    <div class="text-muted text-sm pt-4">
      <h6>Daily Average</h6>
    </div>
    <div class="pb-2">
      {{ track.streams_average | formattedNumber }}
    </div>
    <div class="text-muted text-sm">
      <h6>Streams Yesterday</h6>
    </div>
    <div class="pb-2">
      {{ track.streams_yesterday | formattedNumber }}
    </div>
    <div class="text-muted text-sm pt-4">
      <h6>Label</h6>
    </div>
    <div class="pb-2">
      {{ track.pl_track_label }}
    </div>
    <div class="text-muted text-sm">
      <h6>Copyright</h6>
    </div>
    <div class="pb-2">
      {{ track.pl_track_copyright_c }}
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticInModal",
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.card {
  background-color: #212121;
  border-width: 0;
  padding: 1.5rem !important;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05),
    0 -1px 0px rgba(0, 0, 0, 0.015);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 -1px 0px rgba(0, 0, 0, 0.015);
  min-height: 450px;
}
</style>
