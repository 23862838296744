/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * player module.
 */

import { TRACK, RUN, OPEN_ASIDE, CLEAR_PLAYER } from "./mutation-types";
import getDefaultPlayerState from "./defaultState";

/* eslint-disable no-param-reassign */
export default {
  [TRACK](state, track) {
    state.trackUrl = track.url ? track.url : null;
    state.poster = track.poster ? track.poster : null;
    state.trackData = track.trackData ? track.trackData : {};
    state.name = track.name ? track.name : null;
    state.waveUrl = track.waveUrl ? track.waveUrl : null;
    state.isSoundCloud = track.isSoundCloud ? track.isSoundCloud : false;
    state.isLicensing = track.isLicensing ? track.isLicensing : false;
    state.play = true;
  },
  [RUN](state, play) {
    state.play = play;
  },
  [OPEN_ASIDE](state) {
    state.openAside = Date.now();
  },

  [CLEAR_PLAYER](state) {
    Object.assign(state, getDefaultPlayerState());
  },
};
