export default {
  commentIdentifierForUpdate(state) {
    return state.comments.identifier;
  },
  creativeProjectForUpdateMainAudio(state) {
    return state.creativeProject;
  },
  releaseAssetsForUpdate(state) {
    return state.releaseAssets.identifier;
  },
};
