export const COMMENTS_UPDATE_COUNT = "COMMENTS_UPDATE_COUNT";
export const CREATIVE_PROJECT_ASSET_MARKED_MAIN =
  "CREATIVE_PROJECT_ASSET_MARKED_MAIN";
export const MODAL_OPEN_LICENSING = "MODAL_OPEN_LICENSING";
export const RELEASE_ASSETS_UPLOADED = "RELEASE_ASSETS_UPLOADED";
export const ADD_NEW_RELEASE = "ADD_NEW_RELEASE";

export default {
  COMMENTS_UPDATE_COUNT,
  MODAL_OPEN_LICENSING,
  RELEASE_ASSETS_UPLOADED,
  CREATIVE_PROJECT_ASSET_MARKED_MAIN,
  ADD_NEW_RELEASE,
};
