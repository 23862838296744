import { RESOURCE } from "../../../../routes/const";

export default () => {
  const rules = {
    action: "manage",
    subject: RESOURCE.projectBoard,
  };

  return rules;
};
