import { RESOURCE } from "../const";

export default {
  path: "/playlist/:identifier",
  name: "playlist.index",
  component: () => import("@/views/Playlist/Index.vue"),
  meta: {
    action: "view",
    resource: RESOURCE.publicPages,
  },
};
