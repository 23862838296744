<template>
  <div class="licensing__modal_card p-3 mb-4">
    <h5 class="pb-4">Financial</h5>
    <div class="text-muted text-sm">
      <h6>Total revenue</h6>
    </div>
    <div class="pb-2">
      € {{ track.pl_track_gross_income | formattedNumber }}
    </div>
    <div class="text-muted text-sm pt-4">
      <h6>90 Days</h6>
    </div>
    <div class="pb-2">
      € {{ track.pl_track_projected_income_90_days | formattedNumber }}
    </div>
    <div class="text-muted text-sm">
      <h6>180 Days</h6>
    </div>
    <div class="pb-2">
      € {{ track.pl_track_projected_income_180_days | formattedNumber }}
    </div>
    <div class="text-muted text-sm">
      <h6>360 Days</h6>
    </div>
    <div class="pb-2">
      € {{ track.pl_track_projected_income_360_days | formattedNumber }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FinancialInModal",
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
};
</script>
