const getDefaultPlayerState = () => ({
  trackUrl: null,
  trackData: {},
  poster: null,
  play: false,
  name: null,
  isSoundCloud: false,
  isLicensing: false,
  waveUrl: null,
  openAside: null,
});

export default getDefaultPlayerState;
