<template>
  <div v-if="comments" class="pt-1 p-2">
    <div v-if="comments.length" class="chat-list">
      <div v-for="comment in comments" :key="comment.id">
        <div class="chat-item" data-class="null">
          <a
            :class="{
              circle: !comment.from.press_photo.size_1,
              'bg-warning-lt': !comment.from.press_photo.size_1,
            }"
            href="#"
            class="avatar w-40"
          >
            <img
              v-if="comment.from.press_photo.size_1"
              :src="comment.from.press_photo.size_1"
              :alt="comment.from.full_name"
            />
            <span v-else>{{
              comment.from.full_name || item.title | firstLetter | Capitalize
            }}</span>
          </a>
          <div class="chat-body">
            <div class="chat-date date">
              <div class="d-flex flex-row">
                <div
                  :class="{
                    'align-self-center text-left': canDelete(comment.from.id),
                    'align-self-center  pr-0': !canDelete(comment.from.id),
                  }"
                >
                  {{ comment.from.full_name }} {{ comment.created_at }}
                </div>
                <div v-if="canDelete(comment.from.id)" class="col-1 pl-0">
                  <span
                    class="btn btn-sm pl-1 i-con-h-a pointer pt-0"
                    @click.stop="$emit('deletingComment', comment.id)"
                  >
                    <i class="i-con i-con-trash text-muted"><i /></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              v-for="commentText in comment.list"
              class="text-left rounded msg break-text"
              v-html="commentText"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center text-muted">
        <small>No comments here</small>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CommentsListing",
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(["account"]),
  },
  methods: {
    canDelete(userId) {
      return this.account.id === userId;
    },
  },
};
</script>

<style scoped>
.break-text {
  word-break: break-word;
  width: 100%;
}
@media (max-width: 576px) {
  .chat-date .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}
</style>
