import setLabelBeforeEnter from "@/routes/setLabelBeforeEnter";
import {
  BRIEFING_CREATIVE,
  BRIEFING_SINGLE_PAGE,
  BRIEFING_SYNC,
  RESOURCE,
} from "../const";

export default {
  path: "/briefing",
  name: "briefing.index",
  component: () => import("@/views/Sync/Index.vue"),
  redirect: "/briefing/creative",
  // If the user needs to be authenticated to view this page.
  children: [
    {
      path: "creative",
      name: BRIEFING_CREATIVE,
      component: () => import("@/views/Sync/pages/CreativeBrifieng.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.briefing.main,
      },
    },
    {
      path: "sync",
      name: BRIEFING_SYNC,
      component: () => import("@/views/Sync/pages/SyncPage.vue"),
      meta: {
        auth: true,
        resource: RESOURCE.briefing.main,
      },
    },
    {
      path: "single/:identifier/:parentLabelPath?/:type?",
      component: () => import("@/views/Sync/pages/BriefingSinglePage.vue"),
      name: BRIEFING_SINGLE_PAGE,
      beforeEnter: setLabelBeforeEnter,
    },
  ],
};
