import Notifications from "vue-notification";
import Vue from "vue";

Vue.use(Notifications);
class UseNotieWithArgs {
  static install() {
    // eslint-disable-next-line func-names
    Vue.prototype.$appNotification = function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return {
        /**
         *  @param {string} message
         */
        error(message) {
          self.$notify({
            group: "error",
            type: "error",
            title: "ERROR",
            duration: 4000,
            text: message,
          });
        },
        /**
         *
         *  @param {string} message
         */
        success(message) {
          self.$notify({
            group: "successful",
            type: "success",
            title: "SUCCESS",
            duration: 5000,
            text: message,
          });
        },
        /**
         *
         * @param {string} message
         * @param {object=} options
         * @see https://bootstrap-vue.js.org/docs/components/modal/#modal-message-boxes
         * @returns {Promise<BvMsgBoxData>}
         */
        confirm(message, options = {}) {
          return self.$bvModal.msgBoxConfirm(message, {
            title: "Please Confirm",
            size: "sm",
            dialogClass: "confirmation-dialog",
            modalClass: "confirmation-modal",
            buttonSize: "md",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: false,
            ...options,
          });
        },
      };
    };
  }
}
Vue.use(UseNotieWithArgs);
