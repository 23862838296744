<template>
  <b-modal
    ref="licensingModal"
    size="xl"
    hide-footer
    no-close-on-esc
    class="modal-info"
  >
    <template #modal-header="{ close }">
      <div class="modal-title d-flex">
        <h5 style="padding-top: 6px">Track info</h5>
        <span class="nav-icon i-con-h-a pointer">
          <a
            v-clipboard:copy="
              `${pathRouter}/#/creative/licensing/page/${trackData.identifier}`
            "
            v-clipboard:success="onSuccess"
            v-clipboard:error="onError"
            class="dropdown-item"
          >
            <i class="i-con i-con-page">
              <i />
            </i>
          </a>
        </span>
      </div>
      <span class="text-md l-h-1x pointer" @click="close()"> × </span>
    </template>
    <licensing-demo-info :track="trackData" />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import LicensingDemoInfo from "./LicensingDemoInfo.vue";

export default {
  name: "ModalLicensing",
  components: {
    LicensingDemoInfo,
  },
  data() {
    return {
      trackData: {},
    };
  },
  computed: {
    ...mapGetters({
      labelData: "account/currentLabelData",
    }),
    pathRouter() {
      return window.location.origin;
    },
  },
  created() {
    this.$parent.$on("show-modal", this.showLicensingModal);
  },
  methods: {
    releaseUpdated(release, curWeek, newWeek = null) {
      this.$emit("updated-release", release, curWeek, newWeek);
    },
    showLicensingModal(track) {
      // don't delete this check please
      if (!track) {
        return false;
      }
      // TODO add api request

      this.trackData = { ...track };
      this.$refs.licensingModal.show();
      return true;
    },
    onSuccess() {
      this.$notify({
        group: "successful",
        type: "success",
        title: "SUCCESS",
        duration: 3000,
        text: "Link copied to clipboard.",
      });
    },
    onError() {
      alert("Failed to copy texts");
    },
  },
};
</script>
<style>
.dropdown-item:hover {
  background-color: inherit;
}
</style>
