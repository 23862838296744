<template>
  <transition name="fade">
    <div v-if="isLoading">
      <div :class="classList" :style="{ top: setTop }" style="left: 50%; margin-top: -1rem; margin-left: -1rem"
        role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Loader",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: true,
    },
    setTop: {
      type: String,
      default: "50%",
    },
  },
  computed: {
    ...mapState(["setting", "account"]),
    isRightholder() {
      return this.account.isRightholder;
    },
    classList() {
      return {
        "cc-custom-loader": true,
        fixed: this.isFixed,
        "position-absolute": !this.isFixed,
        leftFoldedMargin: this.isRightholder,
      };
    },
  },
};
</script>

<style scoped>
.ajax-loader {
  background: #121212;
  z-index: 1001;
}

@media screen and (min-width: 992px) {
  .leftFoldedMargin {
    margin-left: 6rem !important;
  }
}
</style>
