export default {
  computed: {
    trackUrl() {
      return this.$store.state.player.trackUrl;
    },
    currentUrl() {
      const { item } = this;

      // eslint-disable-next-line no-underscore-dangle
      const trackAssetUrl = this.haveSinglTrack(item)
        ? item.asset.single_file._single_file.url
        : "";
      return this.getExternalUrl(item) || trackAssetUrl;
    },
  },
  methods: {
    getExternalUrl(item) {
      const URL = item && item.asset && item.asset.external_file_url;
      return URL ? item.asset.external_file_url : false;
    },
    haveSinglTrack(item) {
      const { asset } = item;
      return (
        asset &&
        asset.single_file &&
        // eslint-disable-next-line no-underscore-dangle
        asset.single_file._single_file &&
        // eslint-disable-next-line no-underscore-dangle
        asset.single_file._single_file.url &&
        // eslint-disable-next-line no-underscore-dangle
        asset.single_file._single_file.url !== ""
      );
    },
  },
};
