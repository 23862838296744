<template>
  <h2 :class="titleClass" class="text-3rem">
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    mt: {
      type: Number,
      default: 0,
    },
    mb: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    titleClass() {
      const mTop = `mt-${this.mt}`;
      const mBottom = `mb-${this.mb}`;
      return {
        [mTop]: true,
        [mBottom]: true,
      };
    },
  },
};
</script>
