import { RESOURCE } from "../../routes/const";
import creativeModule from "./AbilitiesByModule/creative/index";
import backOfficeModule from "./AbilitiesByModule/backoffice/index";
import financeModule from "./AbilitiesByModule/finance/index";
import dataEntryModule from "./AbilitiesByModule/dataEntry/index";
import salesModule from "./AbilitiesByModule/sales/index";
import arModule from "./AbilitiesByModule/a_r/index";
import reloadDataModule from "./AbilitiesByModule/reloadServiceData/index";
import generalTasks from "./AbilitiesByModule/generalTasks/index";
import projectBoard from "./AbilitiesByModule/projectBoard/index";
import performancePage from "./AbilitiesByModule/performance/index";

/**
 * @see https://gitlab.com/dev_viaweb/creative_connect/-/wikis/Abilities-For-User
 * @param {object} account
 * @param {array} account.label_managers
 * @param {array} account.creative
 * @param {array} account.demobox
 * @param {array} account.labels_manager_licensing
 * @param {array} account.licensing
 * @param {array} account.back_office
 * @param {array} account.roles
 * @param {boolean} account.isRightholder
 * @param {number} currentLabel
 * @returns {[]}
 */

export default (account, currentLabel) => {
  /**
   * permissions defines
   * https://knightvision.bettyblocks.com/#f84534ae4f9d48bba8623dd530d18b98
   */

  let rules = [];

  rules.push({
    action: "view",
    subject: RESOURCE.publicPages,
  });

  rules.push({
    action: "auth",
    subject: RESOURCE.loggedIn,
  });

  rules = rules
    .concat(creativeModule(account, currentLabel))
    .concat(backOfficeModule(account, currentLabel))
    .concat(financeModule(account, currentLabel))
    .concat(dataEntryModule(account, currentLabel))
    .concat(salesModule(account, currentLabel))
    .concat(reloadDataModule(account, currentLabel))
    .concat(arModule(account, currentLabel))
    .concat(generalTasks(account, currentLabel))
    .concat(performancePage(account, currentLabel))
    .concat(projectBoard());

  if (rules.length > 1) {
    rules.push({
      action: "manage",
      subject: RESOURCE.account.main,
    });
  }

  return rules;
};
