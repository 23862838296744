import store from "@/store";
import PATH from "@/const/PATH";
import BbProxy from "@/proxies/BbProxy";

const SampleData = {
  demo_id: 0,
  status: "",
};

class ProjectProxy extends BbProxy {
  constructor() {
    super("app/creative/project");
  }

  updateField(id, fieldName, fieldValue) {
    if (["target_releasedate", "group_id"].includes(fieldName)) {
      const data = {
        [fieldName]: fieldValue,
        project_id: id,
      };
      if (!fieldValue) {
        data[fieldName] = "delete";
      }
      return this.submit("post", "app/project/group", data);
    }
    return super.update(id, { [fieldName]: fieldValue });
  }

  updateStatus(id, status) {
    const item = { ...SampleData, ...{ demo_id: id, status } };
    return this.submit("post", "/set/demo/status", item);
  }

  getUsers() {
    return new Promise((resolve, reject) => {
      this.submit("get", "app/creative/project/users")
        .then((responses) => {
          const users =
            responses.data &&
            responses.data.users &&
            Array.isArray(responses.data.users)
              ? responses.data.users
              : [];
          if (PATH.ENV === "production") {
            return resolve({
              data: { users: users.filter((user) => user.id !== 6489) },
            });
          }
          return resolve({ data: { users } });
        })
        .catch((e) => {
          console.error(e);
          reject(new Error("'app/creative/project/users wrong data"));
        });
    });
  }

  getMembers(projectId) {
    return this.submit("get", `/${this.endpoint}/${projectId}/shared-members`);
  }

  getSharedLabels(projectId) {
    return this.submit("get", `/${this.endpoint}/${projectId}/shared-labels`);
  }

  relatedComments(id) {
    return this.submit("get", `app/creative/project/${id}/comments`);
  }

  getGroups() {
    return this.submit("get", "app/data/projects_groups");
  }

  getAssets(id) {
    return this.submit("get", `app/creative/project/audio-assets/${id}`);
  }

  getScheduled() {
    return this.submit("get", "app/creative/project/scheduled");
  }

  /**
   * all upcoming release schedule from backoffice be group
   * @param id
   */
  getReleasesByGroup(id) {
    return this.submit("get", `app/releases/${id}/schedule`);
  }

  getAllTracks() {
    return this.all()
      .then((data) => {
        if (!data) {
          return {
            demo: [],
            labels: [],
            total: 0,
          };
        }
        if (data.projects !== undefined) {
          const demos = data.projects.map((track) => {
            /**
             * checking if "file" exists
             */

            if (
              track.asset &&
              track.asset.file &&
              track.asset.file.length > 0
            ) {
              // eslint-disable-next-line no-underscore-dangle
              if (track.asset.file[0]._file0) {
                // eslint-disable-next-line no-underscore-dangle,no-param-reassign
                track.asset.single_file._single_file.url =
                  track.asset.file[0]._file0.url;
              }
            }

            return track;
          });

          // eslint-disable-next-line no-param-reassign
          data.projects = demos;
        } else {
          // eslint-disable-next-line no-param-reassign
          data.projects = [];
          console.error("projects not correct");
        }

        if (data.labels === undefined) {
          data.labels = [];
          console.warn("labels is empty");
        }

        return Promise.resolve(data);
      })
      .catch((error) => {
        console.log("Cannot load account info by route");
        Promise.reject(error);
      });
  }

  getAllTracksWithOutLabel() {
    return this.submit("get", "app/creative/project/all")
      .then((data) => {
        if (!data) {
          return {
            demo: [],
            labels: [],
            total: 0,
          };
        }
        if (data.projects !== undefined) {
          const demos = data.projects.map((track) => {
            /**
             * checking if "file" exists
             */

            if (
              track.asset &&
              track.asset.file &&
              track.asset.file.length > 0
            ) {
              // eslint-disable-next-line no-underscore-dangle
              if (track.asset.file[0]._file0) {
                // eslint-disable-next-line no-underscore-dangle,no-param-reassign
                track.asset.single_file._single_file.url =
                  track.asset.file[0]._file0.url;
              }
            }

            return track;
          });

          // eslint-disable-next-line no-param-reassign
          data.projects = demos;
        } else {
          // eslint-disable-next-line no-param-reassign
          data.projects = [];
          console.error("projects not correct");
        }

        if (data.labels === undefined) {
          data.labels = [];
          console.warn("labels is empty");
        }

        return Promise.resolve(data);
      })
      .catch((error) => {
        console.log("Cannot load account info by route");
        Promise.reject(error);
      });
  }

  /**
   *
   * @param string projectIdentifier
   * @param string assetIdentifier
   * @returns {Promise}
   */
  setMainAudio(projectIdentifier, assetIdentifier) {
    return this.submit(
      "put",
      `/${this.endpoint}/${projectIdentifier}/main-audio/${assetIdentifier}`
    );
  }

  /**
   * Convert Project to track
   *
   * @param  {object} data
   * @param  {string} data.additional_artists_id
   * @param  {string} data.additional_new_artists_names
   * @param  {string} data.main_artists_id
   * @param  {string} data.main_new_artists_names
   * @param  {string} data.master_audio_identifier
   * @param  {string} data.title
   * @param  {string} data.identifier
   * @param  assetIdentifier
   * @returns {Promise}
   */
  convertToTrack(data) {
    const { currentLabel } = store.state.account;
    const params = { ...data };
    params.label_id = currentLabel;
    return this.submit("post", `/${this.endpoint}/convert/track`, params);
  }
}

export default ProjectProxy;
