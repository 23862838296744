/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from "vue";
import Axios from "axios";
import store from "@/store";
import { appVars } from "@/const/AppVars";

Axios.defaults.baseURL = appVars.ApiBaseUrl + "/" + appVars.ApiVersion;

Axios.defaults.headers.common.Accept = "application/json";

Axios.interceptors.response.use(
  (response) => {
    if (
      response.request.responseURL &&
      response.request.responseURL.includes("clients/web/login")
    ) {
      store.dispatch("auth/logout");
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
    }
    if (error.response.status === 403) {
      console.log("Request forbidden");
    }
    return Promise.reject(error);
  }
);

// Bind Axios to Vue.
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, "$http", {
  get() {
    return Axios;
  },
});
