<template>
  <div class="modal-body">
    <div class="wrapper">
      <div class="row">
        <div class="d-flex col-md-6 col-12 pb-3">
          <div class="list-item">
            <track-with-play :item="track" />
          </div>
          <div class="d-flex align-items-center">
            <track-field
              :title="track.title"
              :atrtist-name="track.pl_track_artists"
            />
          </div>
        </div>
        <div class="d-flex col-md-6 col-12 mb-2">
          <div class="ml-lg-auto dropdown mt-2 show">
            <a
              :href="track.spotify_url"
              target="_blank"
              class="btn btn-secondary px-4"
              aria-expanded="false"
              >Open In Spotify
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col mb-4 mb-lg-0">
          <streaming-statistics-modal :track="track" />
          <LicensingDemoComments :track="track" />
        </div>
        <div class="col-12 col-lg-4">
          <licensing-track-data :track="trackData" />
          <statistic-in-modal :track="trackData" />
          <financial-in-modal :track="trackData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LicensingTrackData from "@/views/Creative/components/licensing/LicensingTrackData.vue";
import TrackField from "../../../components/Table/Fields/TrackField.vue";
import LicensingDemoComments from "./Form/LicensingDemoComments.vue";
import TrackWithPlay from "../../../components/Table/Fields/TrackWithPlay.vue";
import StatisticInModal from "./StatisticInModal.vue";
import FinancialInModal from "./FinancialInModal.vue";
import StreamingStatisticsModal from "./StreamingStatisticsModal.vue";
import LicensingTrackDataProxy from "../../../../proxies/creative/licensing/LicensingTrackDataProxy";

export default {
  name: "LicensingDemoInfo",
  components: {
    LicensingTrackData,
    TrackWithPlay,
    TrackField,
    LicensingDemoComments,
    StatisticInModal,
    FinancialInModal,
    StreamingStatisticsModal,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalLoading: false,
      trackData: {},
    };
  },
  created() {
    this.fetchTrackData();
  },
  methods: {
    fetchTrackData() {
      const client = new LicensingTrackDataProxy();
      client
        .getTracksData(this.track.identifier)
        .then((response) => {
          if (response.data) {
            this.trackData = response.data;
          } else {
            console.error("Data is not correct");
          }
        })
        .catch(() => {
          console.error("Cannot load data by route");
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.comments-listing {
  max-height: 343px;
  overflow: auto;
}

.list-item {
  min-width: 60px !important;
}
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
</style>
