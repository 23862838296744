<template>
  <div class="pos-fix w-100 bg-dark" style="height: 100vh; z-index: 9999">
    <div class="h-v d-flex flex align-items-center">
      <div class="mx-auto w-50 animate fadeIn text-center app-loader__logo">
        <img
          src="../assets/img/wide-awake-logo.png"
          alt=""
          class="w-100 inverted"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
};
</script>

<style scoped>
.app-loader__logo {
  animation: fadeIn 2s infinite alternate;
}
</style>
