<template>
  <div :class="blockClass">
    <h4 :class="titleClass" class="text-2rem">
      {{ title }}
    </h4>
    <span v-if="subTitle" class="text-muted">
      {{ subTitle }}
    </span>
  </div>
</template>

<script>
export default {
  name: "FullBlockTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: "",
    },
    mt: {
      type: Number,
      default: 5,
    },
    mb: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    blockClass() {
      const mTop = `mt-${this.mt}`;
      const mBottom = `mb-${this.mb}`;
      return {
        [mTop]: true,
        [mBottom]: true,
      };
    },
    titleClass() {
      const mBottom = this.subTitle.length > 0 ? "mb-1" : "mb-0";
      return {
        [mBottom]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 2rem !important;
}
h4 {
  font-size: 2rem !important;
}
</style>
