import { RESOURCE } from "../const";

export default {
  path: "/data-entry",
  name: "data-entry.index",
  component: () => import("@/views/DataEntry/Index.vue"),
  redirect: "/data-entry/streaming",
  children: [
    {
      path: "streaming",
      component: () => import("@/views/DataEntry/pages/Streaming/Index.vue"),
      name: "dataEntry.streaming",
      meta: {
        auth: true,
        resource: RESOURCE.dataEntry.main,
      },
    },
    {
      path: "emails",
      component: () => import("@/views/DataEntry/pages/DataEntryEmails.vue"),
      name: "dataEntry.emails",
      meta: {
        auth: true,
        resource: RESOURCE.dataEntry.main,
      },
    },
  ],
  meta: {
    auth: true,
    resource: RESOURCE.dataEntry.main,
  },
};
