import types from "./mutation-types";
/* eslint-disable no-param-reassign */
export default {
  [types.SET_RIGHTHOLDER_STATEMENTS]: (state, rightholderData) => {
    state.balancePayable = rightholderData.balance;
    state.totalCosts = rightholderData.costs;
    state.netIncome = rightholderData.income;
    state.payouts = rightholderData.payouts;
  },
  [types.CLEAR_RIGHTHOLDER_STATEMENTS]: (state) => {
    state.balancePayable = 0;
    state.totalCosts = 0;
    state.netIncome = 0;
    state.payouts = 0;
  },
  [types.SET_RIGHTHOLDER_FOR_FINANCE_ADMIN]: (state, user) => {
    state.userId = user.id ? user.id : null;
    state.userIdentifier = user.identifier ? user.identifier : null;
  },
};
