export default {
  totalTracks(state) {
    return state.totalTracks;
  },
  totalShops(state) {
    return state.totalShops;
  },
  totalCountries(state) {
    return state.totalCountries;
  },
  totalRightholders(state) {
    return state.totalRightholders;
  },
  totalTransactions(state) {
    return state.totalTransactions;
  },
  withDrawStatus(state) {
    return state.withDraw.modal.title;
  },
};
