import backOfficeAbilities from "./backoffice";
import ROLES from "../../../../const/Roles";
import { RESOURCE } from "../../../../routes/const";
import catalogAbilities from "./catalog";

/**
 *
 * @param account
 * @param {array} account.label_managers
 * @param {array} account.back_office
 * @param {number} currentLabel
 * @returns {[]}
 */
export default (account, currentLabel) => {
  const {
    roles,
    permissions: {
      label_managers: labelManagers,
      back_office: backOffice,
      catalog,
    },
  } = account;
  let rules = [];
  if (
    labelManagers.includes(currentLabel) ||
    backOffice.includes(currentLabel)
  ) {
    rules = rules.concat(backOfficeAbilities()).concat(catalogAbilities());
  }

  if (catalog.includes(currentLabel)) {
    rules = rules.concat(catalogAbilities());
  }

  if (
    Array.isArray(roles) &&
    roles.map((role) => role.toLowerCase()).includes(ROLES.ProjectBoard)
  ) {
    rules.push({
      action: "manage",
      subject: RESOURCE.backoffice.nested.project_pages,
    });
  }
  return rules;
};
