// MyFilter.js
import Vue from "vue";
import moment from "moment";

Vue.filter("firstLetter", (value) => {
  if (!value) return "";
  const val = value.toString().trim();
  return val.charAt(0);
});

Vue.filter("formattedNumber", (value) => {
  if (value) {
    return parseFloat(value).toLocaleString("de-DE", {
      maximumFractionDigits: 2,
    });
  }
  return 0;
});

Vue.filter('dateParse', function(value, format) {
  return moment(value, format);
});


Vue.filter('dateFormat', function(momentObj, format) {
  return momentObj.format(format);
});


Vue.filter('pluralize', (value, langCode, [singular, plural]) => {
  return value === 1 ? singular : plural;
});

Vue.filter('formattedDate', (date, format) => {
  if (date) {
    return moment(date).format(format);
  }
  return date;
});

Vue.filter("truncate", (text, length, clamp) => {
  const t = text || "";
  const c = clamp || "...";
  const len = length || 30;
  if (t.length <= len) return t;
  let tcText = t.slice(0, len - c.length);
  let last = tcText.length - 1;
  while (last > 0 && tcText[last] !== " " && tcText[last] !== c[0]) last -= 1;
  // Fix for case when text dont have any `space`
  last = last || len - c.length;
  tcText = tcText.slice(0, last);

  return tcText + c;
});

Vue.filter("Capitalize", (value) => value.toUpperCase());

Vue.filter("ucFirst", (str) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
});

Vue.filter("formattedLongDate", (str) => {
  const reg = /_/g;
  if (str) {
    const date = str.slice(0, 10).replace(reg, "-").slice(0, -3);
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da} ${mo} ${ye}`;
  }
  return "";
});

Vue.filter("replaceAssetUrl", (link) => {
  if (!link) return "";
  return link.replace(
    /\/\/assets\.bettyblocks\.com/g,
    "//assets.wdwkmusic.com"
  );
});
