import * as types from "./mutation-types";

/* eslint-disable no-param-reassign */
export default {
  [types.SET_TOTAL_TRACKS](state, count) {
    state.totalTracks = count;
  },
  [types.SET_TOTAL_SHOPS](state, count) {
    state.totalShops = count;
  },
  [types.SET_TOTAL_COUNTRIES](state, count) {
    state.totalCountries = count;
  },
  [types.SET_TOTAL_RIGHTHOLDERS](state, count) {
    state.totalRightholders = count;
  },
  [types.SET_TOTAL_TRANSACTIONS](state, count) {
    state.totalTransactions = count;
  },
  [types.SET_WITHDRAW_STATUS](state, count) {
    state.withDrawStatus = count;
  },
  [types.WITHDRAW_LOADING](state, loading = false) {
    state.withDraw.isLoading = loading;
  },
  [types.SET_WITHDRAW_INFO](state, withdrawData) {
    state.withDraw.isPaymentAvailable = withdrawData.isPaymentAvailable;
    state.withDraw.status = withdrawData.status;
    state.withDraw.amount = withdrawData.amount;
    state.withDraw.createdAt = withdrawData.createdAt;
    state.withDraw.modal.title = withdrawData.modalTitle;
    state.withDraw.button.cssClass = withdrawData.btnClass;
  },
  [types.CLEAR_FINANCE](state) {
    state.totalTracks = 0;
    state.totalShops = 0;
    state.totalCountries = 0;
    state.totalRightholders = 0;
    state.totalTransactions = 0;
    state.withDrawStatus = null;
    state.withDraw.isPaymentAvailable = null;
    state.withDraw.status = null;
    state.withDraw.amount = null;
    state.withDraw.createdAt = null;
    state.withDraw.modal.title = null;
    state.withDraw.button.cssClass = null;
  },
};
