/* ============
 * State of the player module
 * ============
 *
 * The initial state of the account module.
 */
import getDefaultPlayerState from "./defaultState";

const state = getDefaultPlayerState();
export default state;
