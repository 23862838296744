import { RESOURCE } from "../../../../routes/const";
import ROLES from "../../../../const/Roles";

export default (account) => {
  const { roles } = account;
  const rules = [];
  if (
    Array.isArray(roles) &&
    roles.map((role) => role.toLowerCase()).includes(ROLES.SALES.toLowerCase())
  ) {
    rules.push({
      action: "manage",
      subject: RESOURCE.sales.deals,
    });

    rules.push({
      action: "manage",
      subject: RESOURCE.sales.deal,
    });
  }

  if (
    Array.isArray(roles) &&
    roles
      .map((role) => role.toLowerCase())
      .includes(ROLES.TASK_BOARD.toLowerCase())
  ) {
    rules.push({
      action: "manage",
      subject: RESOURCE.sales.tasks,
    });
  }

  return rules;
};
