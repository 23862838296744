import BbProxy from "@/proxies/BbProxy";

class WithdrawLastProxy extends BbProxy {
  /**
   * The constructor for the ArtistProxy.
   */
  constructor() {
    super("app/financial/account/withdraw/last");
  }
}

export default WithdrawLastProxy;
