import { RESOURCE } from "../../../../routes/const";

export default (account, currentLabel) => {
  const {
    permissions: { label_managers: labelManagers, finance: financeManagers },
  } = account;

  const rules = [];

  if (
    labelManagers.includes(currentLabel) ||
    financeManagers.includes(currentLabel)
  ) {
    rules.push({
      action: "manage",
      subject: RESOURCE.performance.main,
    });
  }
  return rules;
};
