/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import Transformer from "./Transformer";

export default class AccountTransformer extends Transformer {
  /**
   * Method used to transform a fetched account.
   *
   * @param account The fetched account.
   *
   * @returns {Object} The transformed account.
   */
  static fetch(account) {
    const permissions = {
      label_managers: [],
      back_office: [],
      creative: [],
      finance: [],
      catalog: [],
      demobox: [],
      project: [],
      topline: [],
      creative_project: [],
      labels_manager_licensing: [],
    };
    return {
      email: account.email,
      firstName: account.first_name,
      lastName: account.last_name,
      photo: account.press_photo.size_1,
      labels: account.labels,
      id: account.id,
      identifier: account.identifier,
      roles: account.roles && Array.isArray(account.roles) ? account.roles : [],
      rightholder: account.rightholder ? account.rightholder : {},
      rightholders:
        account.rightholders && Array.isArray(account.rightholders)
          ? account.rightholders
          : [],
      webRoles:
        account.web_roles && Array.isArray(account.web_roles)
          ? account.web_roles
          : [],
      permissions: { ...permissions, ...account.permissions },
    };
  }

  /**
   * Method used to transform a send account.
   *
   * @param account The account to be send.
   *
   * @returns {Object} The transformed account.
   */
  static send(account) {
    return {
      email: account.email,
      first_name: account.firstName,
      last_name: account.lastName,
    };
  }
}
