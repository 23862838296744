/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */

import Transformer from "../../../transformers/AccountTransformer";
import * as types from "./mutation-types";
import AccountProxy from "../../../proxies/AccountProxy";
import ROLES from "../../../const/Roles";
import LabelsProxy from "../../../proxies/roles/rightholder/LabelsProxy";

export const find = ({ dispatch, commit }) => {
  commit(types.LOADING_ACCOUNT_DATA, true);
  return new Promise((resolve, reject) => {
    const userAction = (user, isRightholder) => {
      commit(types.FIND, user);
      commit(types.LOADING_ACCOUNT_DATA, false);
      dispatch("setting/setFoldeAsideMutation", !isRightholder, { root: true });
      resolve({
        ...user,
        isRightholder,
      });
    };

    new AccountProxy()
      .setParameter("_", Date.now())
      .find()
      .then((response) => {
        const user = Transformer.fetch(response);
        // TODO !!! Important, after we need split up
        //  isRightHolder with (isRightHolder and isRightHolderManager)
        const isRightHolder =
          Array.isArray(user.roles) &&
          (user.roles.includes(ROLES.RIGHTHOLDER) ||
            user.roles.includes(ROLES.RIGHTHOLDER_MANAGER));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const isRightHolderManager =
          Array.isArray(user.roles) &&
          user.roles.includes(ROLES.RIGHTHOLDER_MANAGER);
        const isLabelAdmin =
          Array.isArray(user.roles) && user.roles.includes(ROLES.LABEL_ADMIN);

        if (isRightHolder && !isLabelAdmin) {
          new LabelsProxy(user.identifier)
            .setParameter("_", Date.now())
            .all()
            .then((rightHolderLabels) => {
              const rightholderUser = {
                ...user,
                labels: rightHolderLabels.labels,
              };
              userAction(rightholderUser, isRightHolder);
            });
        } else {
          userAction(user, isRightHolder);
        }
      })
      .catch((eror) => {
        reject(eror);
        console.log("Cannot load account info by route");
      });
  });
};

export const setCurrentLabel = ({ commit, dispatch, rootState }, labelId) => {
  setTimeout(() => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key.slice(0, 5) === "cccs_") {
        console.log(key);
        window.localStorage.removeItem(key);
      }
    }
  });
  commit(types.SET_CURRENT_LABEL, labelId);
  if (rootState.route.name && rootState.route.name.includes("finance")) {
    dispatch("finance/resetCounts", {}, { root: true });
  }
};

export const disableLoading = ({ commit }) => {
  commit(types.LOADING_ACCOUNT_DATA, false);
};

export const reset = ({ commit }) => {
  commit(types.RESET, false);
};

export default {
  find,
  setCurrentLabel,
  disableLoading,
  reset,
};
