import {
  FIND,
  SET_CURRENT_LABEL,
  LOADING_RELEASE_MANAGERS,
} from "../modules/account/mutation-types";
import { ability } from "../../plugins/abilities";
import { API_PARAM_LABEL_ID } from "../../const/labels";
import MentionedUserdProxy from "../../proxies/back-office/MentionedUserdProxy";
import { RESOURCE } from "../../routes/const";

export default (store) =>
  // eslint-disable-next-line prefer-destructuring

  store.subscribe((mutation, state) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case `account/${SET_CURRENT_LABEL}`:
      case `account/${FIND}`: {
        if (!state.account.currentLabel) {
          return false;
        }
        const rights = [
          RESOURCE.backoffice.nested.metadata,
          RESOURCE.backoffice.nested.release_schedule,
        ];
        const userCan = rights.some((permission) =>
          ability.can("manage", permission)
        );
        if (state.account.currentLabel && userCan) {
          const users = new MentionedUserdProxy();

          users
            .setParameter(API_PARAM_LABEL_ID, state.account.currentLabel)
            .all()
            .then((data) => {
              store.commit(`account/${LOADING_RELEASE_MANAGERS}`, data);
            })
            .catch((e) => {
              console.error(e);
              console.error("Cannot load mentioned users");
            });
        }
      }
    }
  });
