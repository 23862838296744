<template>
  <div
    id="app"
    :class="{
      'player-pb': trackUrl,
      'bg-dark': true,
    }"
    class="layout-column"
  >
    <app-loader v-if="loadingAccount" />
    <notifications
      group="successful"
      class="vue-notify"
      position="bottom right"
    />
    <notifications group="error" class="vue-notify" position="bottom right" />
    <router-view />
    <player-html v-if="!loadingAccount" v-show="!isSpotifyIdentifier" />
    <sound-cloud v-if="!loadingAccount && isSoundCloud" />
    <spotify-player v-if="!loadingAccount && isSpotifyIdentifier" />
    <modal-licensing v-if="modalLicensing" ref="licensingModal" />
  </div>
</template>
<script>
/*
 ============
* Entry Point
* ============

*
* The entry point of the application.
*/
import { mapState } from "vuex";
import ModalLicensing from "./views/Creative/components/licensing/ModalLicensing.vue";
import PlayerHtml from "./views/components/GlobalPlayer/AudioPlayer.vue";
import SoundCloud from "./views/components/GlobalPlayer/SoundCloud.vue";
import SpotifyPlayer from "./views/components/GlobalPlayer/SpotifyPlayer.vue";
import AppLoader from "./components/AppLoader.vue";
import { MODAL_OPEN_LICENSING } from "./store/modules/eventBus/mutation-types";
import { RESOURCE } from "./routes/const";

export default {
  /**
   * The name of the application.
   */
  name: "CreativeConnection",

  /**
   * Fires when the app has been mounted.
   */
  components: {
    ModalLicensing,
    PlayerHtml,
    AppLoader,
    SoundCloud,
    SpotifyPlayer,
  },
  computed: {
    ...mapState({
      player: (state) => state.player,
      modal: (state) => state.eventBus.licensingModalOpen,
    }),
    modalLicensing() {
      return this.$can("manage", RESOURCE.creative.nested.licensingPrimary);
    },
    trackUrl() {
      const { trackUrl } = this.$store.state.player;
      return trackUrl && trackUrl !== "" ? trackUrl : false;
    },
    isSpotifyIdentifier() {
      const { trackUrl } = this.$store.state.player;
      return trackUrl && trackUrl.includes("spotify:");
    },
    isSoundCloud() {
      return this.$store.state.player.isSoundCloud;
    },
    loadingAccount() {
      return this.$store.state.account.loading;
    },
  },
  watch: {
    "modal.open": {
      handler(open) {
        if (open && Object.keys(this.modal.trackData).length > 0) {
          this.$emit("show-modal", this.modal.trackData);
          this.$store.commit(`eventBus/${MODAL_OPEN_LICENSING}`, {
            open: false,
            trackData: this.modal.trackData,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    // If the user is authenticated,
    // fetch the data from the API
    if (this.$store.state.auth.authenticated) {
      this.$store.dispatch("account/find");
    }
    // this.$store.dispatch('setting/fetchAllStatuses');
  },
};
</script>
