const getDefaultAccountState = () => ({
  email: null,
  firstName: null,
  lastName: null,
  photo: null,
  labels: [],
  currentLabel: null,
  id: null,
  identifier: null,
  loading: false,
  releaseManagers: [],
  roles: [],
  rightholders: [],
  webRoles: [],
  assignedUsersInDeal: [],
  isRightholder: false,
  permissions: {
    back_office: [],
    catalog: [],
    creative: [],
    demobox: [],
    finance: [],
    label_managers: [],
    project: [],
    topline: [],
    licensing: [],
    creative_project: [],
    labels_manager_licensing: [],
  },
  rules: [],
  homepage: "",
  rightholder: {
    bank_account_data: {
      bank_account_name: "",
      iban: "",
      country: "",
      address: "",
      city: "",
      postal_code: "",
      bank_country: "",
      routing_number: "",
      account_number: "",
      account_type: "",
    },
    last_withdraw: null,
    payable_income: {
      currency: "",
      value: null,
    },
  },
});

export default getDefaultAccountState;
