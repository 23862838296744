import { RESOURCE } from "@/routes/const";

export default {
  path: "/performance",
  name: "performance.index",
  component: () => import("@/views/Performance/Index.vue"),
  meta: {
    auth: true,
    resource: RESOURCE.finance.main,
  },
};
