import BbProxy from "@/proxies/BbProxy";

class DemoCommentsProxy extends BbProxy {
  constructor() {
    super("app/data/demo/messages");
  }

  all(demoIdentifier) {
    this.setParameter("demo_identifier", demoIdentifier);
    return this.submit("get", `${this.endpoint}`).then((data) => {
      const comments = [];
      let currentComment = {};
      const messageFrom = {};
      data.forEach((comment) => {
        const regExp = /\[user\](.*?)\[\/user\]/g;
        const highlightFunction = (match, p1) => {
          const userName = $(`<div>${p1}</div>`).text();
          return `<span class="badge badge-secondary text-uppercase" style="line-height: 1.25">@${userName}</span>`;
        };
        if (messageFrom.id !== comment.from.id) {
          if (currentComment.list && currentComment.list.length > 0) {
            comments.push(currentComment);
          }
          currentComment = {
            id: comment.id,
            from: comment.from,
            created_at: comment.created_at,
            list: [],
          };
          // messageFrom = comment.from;
        }

        if (comment.message) {
          currentComment.list.push(
            comment.message
              .replace(regExp, highlightFunction)
              .replace(/\[br\]/g, "<br/>")
          );
        }
      });

      if (currentComment.list && currentComment.list.length > 0) {
        comments.push(currentComment);
      }

      return comments;
    });
  }

  destroy(id) {
    return this.submit("post", `app/data/message/delete/${id}`);
  }

  /**
   * Method used to create an comment.
   *
   * @param {string} identifier demo.identifier.
   * @param {string} message
   * @param {string} htmlMessageText    message with html tags
   * @param {string} members    users ids with comma separate.
   *
   * @returns {Promise} The result in a promise.
   */
  create(identifier, message, htmlMessageText, members) {
    const formData = new FormData();
    formData.append("identifier", identifier);
    formData.append("message", message);
    formData.append("html_message", htmlMessageText);
    formData.append("members", members);
    return this.submit("post", `/${this.endpoint}`, formData);
  }
}

export default DemoCommentsProxy;
