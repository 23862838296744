import { RESOURCE, LICENSING_ADMIN_DASHBOARD_PAGE } from "../const";

export default {
  path: "/labels",
  name: "labels.index",
  component: () => import("@/views/labelsAdmin/Index.vue"),
  redirect: "/labels/licensing-dashboard",

  children: [
    {
      path: "/labels/licensing-dashboard",
      component: () => import("@/views/labelsAdmin/pages/Dashboard/Index.vue"),
      name: LICENSING_ADMIN_DASHBOARD_PAGE,
      meta: {
        auth: true,
        resource: RESOURCE.creative.nested.unassigned,
      },
    },
    // {
    //   path: '/labels/create',
    //   component: () => import('@/views/labelsAdmin/pages/Labels/Create.vue'),
    //   name: 'labels.create',
    //   meta: {
    //     auth: true,
    //     resource: RESOURCE.creative.nested.create,
    //   },
    // },
    // {
    //   path: '/labels/:id/view',
    //   component: () => import('@/views/labelsAdmin/pages/Labels/View.vue'),
    //   name: 'labels.view',
    //   meta: {
    //     auth: true,
    //     resource: RESOURCE.labels.view,
    //   },
    // },
    // {
    //   path: '/labels/:id/edit',
    //   component: () => import('@/views/labelsAdmin/pages/Labels/Edit.vue'),
    //   name: 'labels.edit',
    //   meta: {
    //     auth: true,
    //     resource: RESOURCE.labels.edit,
    //   },
    // },
  ],

  // If the user needs to be authenticated to view this page.
  meta: {
    auth: true,
    resource: RESOURCE.creative.main,
  },
};
