import { RESOURCE } from "../const";

export default {
  path: "/account",
  name: "account.index",
  component: () => import("@/views/Account/Index.vue"),

  // If the user needs to be authenticated to view this page.
  meta: {
    auth: true,
    resource: RESOURCE.account.main,
  },
};
