/* ============
 * Mutation types for the demobox module
 * ============
 *
 * The mutation types that are available
 * on the demobox module.
 */

export const SET_PRIMARY_DEMO_LIST = "SET_PRIMARY_DEMO_LIST";

export const SET_SECONDARY_DEMO_LIST = "SET_SECONDARY_DEMO_LIST";

export const REMOVE_DEMO_FROM_LIST = "REMOVE_DEMO_FROM_LIST";
export const ADD_DEMO_TO_LIST = "ADD_DEMO_TO_LIST";
export const SET_PRIMARY_REJECT_COUNT = "SET_PRIMARY_REJECT_COUNT";
export const SET_PRIMARY_REJECT_DATA = "SET_PRIMARY_REJECT_DATA";
export const SET_SECONDARY_REJECT_COUNT = "SET_SECONDARY_REJECT_COUNT";
export const SET_SECONDARY_REJECT_DATA = "SET_SECONDARY_REJECT_DATA";
export const CLEAR_PRIMARY_DATA = "CLEAR_PRIMARY_DATA";
export const SET_PRIMARY_APPROVED_DATA = "SET_PRIMARY_APPROVED_DATA";
export const CLEAR_SECONDARY_DATA = "CLEAR_SECONDARY_DATA";
export const SET_STARRED_DEMO = "SET_STARRED_DEMO";
export const SET_STATUS = "SET_STATUS";
export const SET_SIGNED_DATA = "SET_SIGNED_DATA";
export const SET_APPROVED_LOADING = "SET_APPROVED_LOADING";
export const SET_SIGNED_LOADING = "SET_SIGNED_LOADING";

export default {
  SET_PRIMARY_DEMO_LIST,
  SET_SECONDARY_DEMO_LIST,
  REMOVE_DEMO_FROM_LIST,
  ADD_DEMO_TO_LIST,
  SET_PRIMARY_REJECT_COUNT,
  SET_SECONDARY_REJECT_DATA,
  CLEAR_PRIMARY_DATA,
  CLEAR_SECONDARY_DATA,
  SET_STARRED_DEMO,
  SET_STATUS,
  SET_PRIMARY_APPROVED_DATA,
  SET_SIGNED_DATA,
  SET_APPROVED_LOADING,
};
